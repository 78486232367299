import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import _get from 'lodash/get'

import Container from '../Container'
import H2 from '../H2'
import Image from '../Image'
import vars from '../../styles/vars'
import utils from '../../utils'
import media from '../../styles/media'
import theme from '../../styles/theme'


const ImageWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	> img {
		height: auto;
		width: 100%;
	}
`
const ImageOnly = ({ type, background_image }) => {
	return (
		<Container type={type} fullWidth fullSectionWidth noPadding>
			{/* All images are provided double the actual design size.
        	Therefore, the max-width is set to half the image width. */}
			{background_image && (
				<ImageWrapper width={_get(background_image, 'dimensions.width')}>
					<Image {...background_image} />
				</ImageWrapper>
			)}
		</Container>
	)
}

export default ImageOnly
