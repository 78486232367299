import styled from 'styled-components'
import { variant } from 'styled-system'

import theme from '../styles/theme'

const PVariant = styled('p')(
  variant({
    variants: {
      primary: {
        fontSize: '1.0625rem',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        lineHeight: '1.8rem',
        letterSpacing: '0.029rem'
      },
      secondary: {
        fontSize: '1.0625rem',
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
      tertiary: {
        fontSize: '1.3125rem',
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
      smallLabel: {
        fontSize: '1rem',
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
      smallHeading: {
        fontSize: '0.937rem',
        fontFamily: 'RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        lineHeight: 1.5,
        letterSpacing: 0
      },
      percentNumbers: {
        fontSize: '1.3125rem',
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
      subtitle: {
        fontSize: '1.375rem',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        lineHeight: '2rem'
      },
      legal: {
        fontSize: '1.0625rem',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
      },
      richText: {
        fontSize: '1.0625rem',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        lineHeight: '1.8rem',
        letterSpacing: '0.029rem',
        paddingBottom: '1.5rem'
      },
      largeLabel: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.25rem',
        lineHeight: 1.26
      },
      arrowBody: {
        fontSize: '.7rem',
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        lineHeight: '1.1rem'
      },
    }
  })
)

const P = styled(PVariant)`
  color: ${props => props.color === 'light' ? theme.colors.white : theme.colors.dark};
  text-align: ${props => props.textAlign};
`

export default P
