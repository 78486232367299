import React from 'react'
import { graphql } from 'gatsby'
import _map from 'lodash/map'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import H2 from '../H2'
import H3 from '../H3'
import Image from '../Image'
import Button from '../Button'

import utils from '../../utils'
import media from '../../styles/media'

import Container from '../Container'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
`
const TitleWrapper = styled.div`
	text-align: center;
	padding-bottom: 2rem;
	${media.greaterThan('mobile')`
    max-width: 80%;
  `};
`
const SubtitleWrapper = styled.div`padding: 0 0.5rem;`

const ItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	${media.greaterThan('desktop')`
    flex-direction: row;
    padding-bottom: 1.5rem;
  `};
`
const Item = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	text-align: center;
	align-items: center;
	${media.greaterThan('mobile')`
    margin: 0 1.8rem;
  `};
`
const ItemTitle = styled.h3`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.562rem;
	line-height: 2.062rem;
	margin-bottom: 0.6rem;
`
const ItemSubTitle = styled.h4`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.062rem;
	line-height: 1.25rem;
	margin-bottom: 1.4rem;
`
const ItemImageWrapper = styled.div`
	> img {
		width: 100%;
	}
	padding: 1.5rem 3rem;
	${media.greaterThan('mobile')`
		width: 240px;
		padding: 1.5rem 0;
	`};
	${media.greaterThan('desktop')`
		width: 100%;
		padding: 2rem 4rem;
	`};
	${media.greaterThan('largeDesktop')`
		padding: 2rem 7rem;
	`};
`
const ContentWrapper = styled.div`
	padding-bottom: 1.5rem;
	${media.greaterThan('mobile')`
    max-width: 404px;
    margin: 0 auto;
  `};
`
const ItemRichTextWrapper = styled.div`
	> * {
		color: ${(props) => props.theme.colors.white};
	}
`

const ColumnItem = ({ title, sub_title, content, image, imageSharp }) => {
	return (
		<Item>
			{image && (
				<ItemImageWrapper>
					<Image {...image} {...imageSharp} />
				</ItemImageWrapper>
			)}
			<ContentWrapper>
				<ItemTitle>{title}</ItemTitle>
				<ItemSubTitle>{sub_title}</ItemSubTitle>
				<ItemRichTextWrapper>
					<RichText render={content} htmlSerializer={utils.htmlSerializer} />
				</ItemRichTextWrapper>
			</ContentWrapper>
		</Item>
	)
}

const ColumnsListing = ({ type, title, sub_title, button_title, button_link, repeatableFields }) => {
	return (
		<Container type={type} fullWidth backgroundColor={'dark'}>
			<Wrapper>
				<TitleWrapper>
					<H2 variant="heading" color="light" textAlign="center">
						{title}
					</H2>
					<SubtitleWrapper>
						<H3 variant="secondary" color="light" textAlign="center">
							{sub_title}
						</H3>
					</SubtitleWrapper>
				</TitleWrapper>
				{repeatableFields && (
					<ItemsContainer>
						{_map(repeatableFields, (field, index) => {
							return <ColumnItem key={index} {...field} />
						})}
					</ItemsContainer>
				)}
				<Button href={utils.linkResolver(button_link)} variant="secondary">
					{button_title}
				</Button>
			</Wrapper>
		</Container>
	)
}

export default ColumnsListing
