const theme = {
  colors: {
    darkSecondary: "#262626",
    darkBackground: '#020E29',
    smallPanelColorOne: "#EC1C58",
    smallPanelColorTwo: "#F73C49",
    largePanelColorOne: "#020B26",
    largePanelColorTwo: "#009FB7",
    primary: "linear-gradient(to right, #020B26 0%, #021630 10%, #021E39 20%, #1d3b54 50%, #03455E 100%)",
    secondary: "linear-gradient(42.32deg, #F73C49 0%, #EC1C58 100%)",
    tertiary: "linear-gradient(131.02deg, #000000 0%, #1D1D1D 100%)",
    light: "#F4F4F8",
    lightSecondary: "#9A9A9A",
    dark: "#313131",
    black: '#262626',
    white: "#FFFFFF",
    greyBlue: "#4e788a",
    backgroundBlack: "#000000",
    green:"#58A720",
    grey: "#A2A3A5"
  }
}

export default theme

