import React from 'react'
import { navigate } from '@reach/router'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import _get from 'lodash/get'
import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'

import H1 from '../H1'
import H2 from '../H2'
import Container from '../Container'
import Image from '../image'
import media from '../../styles/media'
import vars from '../../styles/vars'
import utils from '../../utils'
import useWindowSize from '../../hooks/useWindowSize'
import Button from "../Button"

const TitleWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	max-width: 54rem;
	margin: 0 auto;
	${media.greaterThan('largeDesktop')`
    max-width: 76rem;
  `};
`
const SubTitleWrapper = styled.div`
	padding: 0 4rem;
	${media.lessThan('tablet')`
    padding: 0;
  `};
`

const ButtonWrapper = styled.div`
    padding: 1.5rem 0rem 0rem 0rem;
	margin: 1.5rem 0rem 0rem 0rem;
	width: 100%;
	height: 100%;
	text-align: center;
	${media.greaterThan('tablet')`
    padding: 1.5rem 1rem 1.5rem 1rem;
	margin: 0rem 0rem;
	position: ${(props) => props.overImage === true ? 'absolute' : 'relative' };
	display: flex;
	justify-content: center;
	align-items: start;
  `};
  ${media.greaterThan('largeDesktop')`
	align-items: center;
  `};
`

const ImageContainer = styled.div`
	position: relative;
	text-align: center;
	margin-bottom: 6.25rem;
	margin: 1rem auto;
	max-width: ${(props) => props.maxWidth}px;
	> img {
		width: 100%;
	}
`

const BottomTitleWrapper = styled(TitleWrapper)`
  text-align: center;
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 58rem;
`

const BottomTitle = styled.div`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.562rem;
	line-height: 2.065rem;
	margin-bottom: 1.6rem;
	color: ${(props) => props.theme.colors.white};
`

const BottomSubTitle = styled.div`
	font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.062rem;
	line-height: 1.687rem;
	letter-spacing: 0.029rem;
	color: ${(props) => props.theme.colors.white};
`

const Description = styled(TitleWrapper)`
  text-align: center;
  margin: 0 auto;
  padding: 2rem 0;
  max-width: 50rem;
	> p, h3 { color: ${({ theme }) => theme.colors.white}; }
`

const handleOnClick = (formUrl) => {
	if (typeof window !== `undefined`) {
		const scrollConfig = { behavior: 'smooth' }
		const elmntToScrollTo = window.document.querySelectorAll("section[type='contact_form']")[0]
		if (elmntToScrollTo) {
			elmntToScrollTo.scrollIntoView(scrollConfig)
		} else {
			navigate(formUrl)
		}
	}
}

const Hero = ({
	type,
	pageTitle,
	title,
	sub_title,
	bottom_title,
	bottom_sub_title,
	background_image,
	background_cover_image,
	mobile_image,
	description,
	is_top_content,
	request_demo_button,
	demo_button_text,
	secondary_button_link,
	secondary_button_title
}) => {
	
	const CtaButtons = ({showOverImage}) => {
		return ( request_demo_button || secondary_button_link ) ? (
			<ButtonWrapper overImage={showOverImage}>
				{request_demo_button && (
					<Button onClick={() => handleOnClick('/retailos/request-a-demo')} variant='heroButton'>
						{demo_button_text || 'Request a Demo'}
					</Button>
				)}
				{secondary_button_title && secondary_button_link && windowSize > vars.breakpoints.mobile * 16 && (
					<Button href={utils.linkResolver(secondary_button_link)} variant='heroButton'>
						{secondary_button_title}
					</Button>
				)}
			</ButtonWrapper>
		) : null
	}
	
	const windowSize = useWindowSize()
	const dimensions = _get(background_image, 'dimensions')
	const backgroundImageUrl = _get(background_image, 'url', '')
	// Remove query params that prismic uses to compress image and make it blurry
	const backgroundImageUrlCleaned = backgroundImageUrl.substring(0, backgroundImageUrl.lastIndexOf("?"))

	const heroImageCover = background_cover_image || false

	return (
		<Container
			type={type}
			fullWidth
			isOverflowToTop={_isNull(is_top_content) || _isUndefined(is_top_content) ? true : is_top_content}
			backgroundColor={'dark'}
			backgroundImageUrl={backgroundImageUrlCleaned}
			dimensions={dimensions}
			backgroundCover={heroImageCover}
		>
			<TitleWrapper>
				<H1 variant="primary" color="light">
					{title}
				</H1>
				<SubTitleWrapper>
					<H2 variant="primary" color="light">
						{sub_title}
					</H2>
				</SubTitleWrapper>
			</TitleWrapper>	
			{ windowSize < vars.breakpoints.mobile * 16 && pageTitle !== 'Homepage' ? (
				!heroImageCover && mobile_image && (
					// All the hero images are provided double the actual design size.
					// Therefore, the max-width is set to half the image width.
					<ImageContainer maxWidth={dimensions.width / 2}>
						<Image {...mobile_image} />
					</ImageContainer>
				)
			) : (
				!heroImageCover && background_image && (
					// All the hero images are provided double the actual design size.
					// Therefore, the max-width is set to half the image width.
					// showOverImage - require buttons in front of image on homepage, above image on solutions pages
					<ImageContainer maxWidth={dimensions.width / 2}>
						<CtaButtons showOverImage={pageTitle === 'Homepage'} />
						<Image {...background_image} />
					</ImageContainer>
				)
				)} 
			{heroImageCover && <CtaButtons />}
			{!mobile_image && !background_image && <CtaButtons />}
			{bottom_title && (
				<BottomTitleWrapper>
					<BottomTitle>{bottom_title}</BottomTitle>
					<BottomSubTitle>{bottom_sub_title}</BottomSubTitle>
				</BottomTitleWrapper>
			)}
			{description && (
				<Description>
					<RichText render={description} htmlSerializer={utils.htmlSerializer} />
				</Description>
			)}
		</Container>
	)
}

export default Hero
