import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import _map from 'lodash/map'

import H2 from '../H2'
import Container from '../Container'
import Image from '../Image'

import vars from '../../styles/vars'
import media from '../../styles/media'
import utils from '../../utils'

const Wrapper = styled.div`
	${media.greaterThan('mobile')`
		margin-top: 1.25rem;
		max-width: ${vars.contentWidth.md}rem;
		margin: 0 auto;
		padding: 0;
  `};
	${media.greaterThan('desktop')`
  	max-width: ${vars.contentWidth.lg}rem;
  `};
`

const TitleWrapper = styled.div`
	max-width: ${(props) => (props.titleLength ? '36.625rem' : '65.625rem')};
	${media.lessThan('tablet')`
    max-width: 100%;
  `};
`

const TextWrapper = styled.div`
	width: 45%;
	${(props) => `padding-${props.paddingDirection}: 1.25rem`};
	${media.lessThan('tablet')`
    width: 100%;
    padding: 0;
  `};
`

const ImageWrapper = styled.div`
	width: 45%;
	${(props) => `padding-${props.paddingDirection}: 1.25rem`};
	> img {
		max-width: 100%;
		height: auto;
	}
	${media.lessThan('tablet')`
		width: 100%;
    padding: 0;
  `};
`

const BlockWrapper = styled.div`
	display: flex;
	flex-direction: ${(props) => props.flexDirection};
	align-items: center;
	justify-content: space-between;
	${media.lessThan('tablet')`
		flex-direction: column;
  `};
`

const Block = ({ index, image, text }) => {
	const getFlexDirection = (index) => (index % 2 === 0 ? 'row' : 'row-reverse')
	const flexDirection = getFlexDirection(index)
	return (
		<BlockWrapper flexDirection={flexDirection}>
			<TextWrapper paddingDirection={flexDirection === 'row' ? 'right' : 'left'}>
				<RichText render={text} htmlSerializer={utils.htmlSerializer} />
			</TextWrapper>

			<ImageWrapper paddingDirection={flexDirection === 'row-reverse' ? 'right' : 'left'}>
				<Image {...image} />
			</ImageWrapper>
		</BlockWrapper>
	)
}

const BlockList = ({ type, title, repeatableFields, noBottomPadding }) => {
	return (
		<Container type={type} noBottomPadding={noBottomPadding}>
			<Wrapper>
				<TitleWrapper>
					<H2 variant="heading">{title}</H2>
				</TitleWrapper>
				{_map(repeatableFields, (item, index) => <Block key={index} index={index} {...item} />)}
			</Wrapper>
		</Container>
	)
}

export default BlockList
