import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H3 from '../H3'
import H2 from '../H2'
import P from '../P'
import Image from '../Image'
import Button from '../Button'

import media from '../../styles/media'
import utils from '../../utils'
import vars from '../../styles/vars'

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	> * {
		font-family: Futura, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
			"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	}
	${media.greaterThan('mobile')`
		padding: 3rem;
		padding-top: 4rem;
	`};
	${media.greaterThan('tablet')`
		padding-left: 15rem;
		padding-right: 15rem;
	`};
`
const ImageWrapper = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
`

const ButtonWrapper = styled.div`
	text-align: center;
`

const ParagraphWrapper = styled.div`
	font-size: 30px;
	text-align: center;
	line-height: 1.3;
	font-family: 'Roboto', sans-serif;
	font-weight: light;
	padding: 2rem;
`

const AuthorWrapper = styled.div`
	font-size: 30px;
	text-align: center;
	font-family: 'Roboto Bold', sans-serif;
	font-weight: 800;
`

const ImageStyle = styled.div`
	text-align: center;
	width: 80px;
	height: 80px;
	padding-bottom: 2rem;
	> img {
		width: 100%;
	}
	${media.lessThan('tablet')`
		width: 40px;
		height: 40px;
		padding-bottom: 0rem;
	`};
`


const Quote = (props) => {
	const { quote_image, quote_detail, author, button_text, button_url } = props
	return (
		<Container backgroundColor="dark" fullSectionWidth fullWidth>
			<TextWrapper>
				<ImageWrapper>
					<ImageStyle><Image {...quote_image} /></ImageStyle>
				</ImageWrapper>

				<H3 variant="boxTitleLarge" textAlign="left" color={'light'}>
					<ParagraphWrapper>
						{quote_detail}
					</ParagraphWrapper>
				</H3>

				<H2 variant="primary" color={'light'}>
					<AuthorWrapper>
						{author}
					</AuthorWrapper>
				</H2>

				{button_text && button_url ? (
					<ButtonWrapper>
						<Button href={utils.linkResolver(button_url)} style={{backgroundColor: "red", color: "white"}} variant='heroButton' onClick={button_url}>
							{button_text}
						</Button>
					</ButtonWrapper>
				) : null}
			</TextWrapper>
		</Container>
	)
}

export default Quote
