module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-4272922-2","cookieName":"gdpr-google-analytics","anonymize":true,"allowAdFeatures":false,"defer":true,"exclude":["/preview/**","/404"]},"googleTagManager":{"trackingId":"GTM-KGFB9FC","cookieName":"gdpr-google-tagmanager","dataLayerName":"dataLayer","gtmAuth":null,"gtmPreview":null,"routeChangeEvent":"gatsby-route-change"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.redant.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Red Ant","short_name":"Red Ant","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/ra-logo.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7cb9f2d34dd8162f61d26d8b582f93f3"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"redant-website-2020","defaultLang":"en-gb","previews":false,"pages":[{"type":"Solutions","match":"/retailos/:uid","path":"/preview/retailos","component":"/Users/vagrant/git/src/templates/solutions.js"},{"type":"Product_page","match":"/retailos/:uid","path":"/preview/retailos","component":"/Users/vagrant/git/src/templates/product_page.js"},{"type":"Company","match":"/about/:uid","path":"/preview/about","component":"/Users/vagrant/git/src/templates/company.js"},{"type":"Product_page","match":"/request-a-demo","path":"/preview/request-a-demo","component":"/Users/vagrant/git/src/templates/product_page.js"},{"type":"Legal","match":"/:uid","path":"/legal-preview","component":"/Users/vagrant/git/src/templates/legal.js"},{"type":"Blog_post","match":"/blog/:uid","path":"/preview/blog","component":"/Users/vagrant/git/src/templates/blog_post.js"},{"type":"Whitepaper","match":"/research/:uid","path":"/preview/research","component":"/Users/vagrant/git/src/templates/whitepaper.js"},{"type":"Case_study","match":"/case-study/:uid","path":"/preview/case-study","component":"/Users/vagrant/git/src/templates/case_study.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
