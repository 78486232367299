import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H2 from '../H2'
import Image from '../Image'
import vars from '../../styles/vars'
import utils from '../../utils'

const Wrapper = styled.div`
	margin: 0 auto;
	${media.greaterThan('mobile')`
    max-width: ${vars.contentWidth.md}rem;
    padding: 0 2rem;
  `} ${media.greaterThan('desktop')`
    max-width: ${vars.contentWidth.lg}rem;
    padding: 0;
  `};
`

const BodyContainer = styled.div`
	display: flex;
	padding-top: 1rem;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	${media.lessThan('tablet')`
    flex-direction: column;
    align-items: center;
  `};
`
const TextContainer = styled.div`
	width: 50%;
	padding-right: 1.25rem;
	${media.lessThan('tablet')`
    width: 100%;
    padding-right: 0;
  `};
`
const ImageWrapper = styled.div`
	width: 100%;
	padding-left: 1.25rem;
	> img {
		width: 100%;
	}
	${media.greaterThan('mobile')`
    width: 40%;
  `};
`
const TitleContainer = styled.div``

const ColumnPanel = (props) => {
	const { type, title, article_image, paragraph, background_color } = props || {}
	return (
		<Container type={type} backgroundColor={background_color}>
			<Wrapper>
				<TitleContainer>
					<H2 variant="heading">{title}</H2>
				</TitleContainer>
				<BodyContainer>
					<TextContainer>
						<RichText render={paragraph} htmlSerializer={utils.htmlSerializer} />
					</TextContainer>
					<ImageWrapper>
						<Image {...article_image} />
					</ImageWrapper>
				</BodyContainer>
			</Wrapper>
		</Container>
	)
}

export default ColumnPanel
