import React from 'react'
import styled, { css } from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'

import media from "../../styles/media"
import Container from "../Container"
import H2 from "../H2"
import H3 from "../H3"
import P from "../P"
import Image from "../Image"
import Button from "../Button"
import utils from "../../utils"


const Wrapper = styled.div``

const TitleWrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 1.6rem;
  text-align: center;
`

const IconsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-top: 20px;
	${media.greaterThan("tablet")`
		padding-left: 15rem;
		padding-right: 15rem;
	`}
`

const InnerIconsWrapper = styled.div`
  	width: 100%;
	display: flex;
  	flex-direction: column;
  	justify-content: space-around;
	align-items: center;
	margin-top: 40px;
	max-width: ${({ maxWidth }) => maxWidth};

  ${media.greaterThan("mobile")`
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: baseline;
  `}

	${media.greaterThan("tablet")`
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: baseline;
  `}

	${media.greaterThan("desktop")`
		flex-direction: row;
		justify-content: space-between;
		align-items: baseline;
  `}
`

const IconContainer = styled.div`
	text-align: center;
	margin-bottom: 40px;
`

const ImageWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
	> img {
		height: auto;
		width: 100%;
	}
`

const IconTextWrapper = styled.div`
	padding-top: 2rem;
	> h3 { margin-bottom: 5px; }
	> p { max-width: 390px; }
`

const ButtonWrapper = styled.div`
	padding: 2rem;
	margin-top: 2rem;
	${media.greaterThan('tablet')`
    padding: 3rem 1rem 1rem 1rem;
  `};
`

const TextWrapper = styled.div`
	padding-top: 1rem;
	margin: auto;
	width: 100%;
	${media.greaterThan('mobile')`
		max-width: ${(props) => (props.numberOfColumns === 3 ? '15.4375rem' : '8.4375rem;')};
	`}
`
	
const SecondTitleWrapper = styled.div`
	max-width: 1069px;
	padding-bottom: 20px;
`

const SecondTitleStyle = styled.div`
	font-size: 48px
`

const textColor = (background_color) => background_color && background_color !== 'dark' ? 'dark' : 'light'
const buttonColor =  (background_color) => background_color && background_color !== 'dark' ? 'primary' : "secondary"

const IconsListing = ({ pageTitle, title, fields, text_color }) => {
	// page specific configuration
	const pageConfig = {
		'Assisted Sales': { maxIconWrapperWidth: '950px' },
		'Click & Collect': { maxIconWrapperWidth: '950px' },
		'Mobile POS': { maxIconWrapperWidth: '1002px' },
		'Clienteling': { maxIconWrapperWidth: '1080px' }
	}[pageTitle] || { maxIconWrapperWidth: '1080px' };
	
	function escapeRegExp(string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
	}

	function replaceAll(str, match, replacement){
		return str.replace(new RegExp(escapeRegExp(match), 'g'), ()=>replacement);
	}

	return (
		<InnerIconsWrapper
		maxWidth={pageConfig.maxIconWrapperWidth}>
		{_map(fields, (field, index) => {
			const { icon, icon_subtitle, icon_title } = field || {}
			const subTitlesExists = icon_title || icon_subtitle

			const updated_icon_title = replaceAll(icon_title, '\n', '<br/>')
			

			return (icon || icon_title || icon_subtitle) && (
				<IconContainer key={index} subTitlesExists={subTitlesExists}>
					{/* All images are provided double the actual design size.
						Therefore, the max-width is set to half the image width. */}
					{icon && (
						<ImageWrapper maxWidth={_get(icon, 'dimensions.width') / 2}>
							<Image {...icon} />
						</ImageWrapper>
					)}
					{icon_title === null ? (
						<TextWrapper numberOfColumns={fields.length}>
							<P variant="secondary" color={text_color}>
								{icon_subtitle}
							</P>
						</TextWrapper>
					) : (
						<IconTextWrapper>
								<H3 variant="boxTitle" color={text_color}>
								<div
									dangerouslySetInnerHTML={{__html: updated_icon_title}}
								/>
								</H3>
								<P variant="secondary" color={text_color}>
									{icon_subtitle}
								</P>
							</IconTextWrapper>
						)}
				</IconContainer>
			)
		})}
		</InnerIconsWrapper>
	)
}

const IconBanner2 = (props) => {
	const { type, pageTitle, title, subtitle, second_title, button_name, button_link, repeatableFields, select_background_color } = props || {}
	
	return (
		<Container type={type} backgroundColor={select_background_color || `dark`}> 
			<Wrapper>
				{(_size(repeatableFields) > 0 || second_title) && (
					<IconsWrapper>
						{second_title && (
							<SecondTitleWrapper>
								<H3 variant="boxTitle" color={textColor(select_background_color)}>
									<SecondTitleStyle>
										{second_title}
									</SecondTitleStyle>
								</H3>
							</SecondTitleWrapper>
						)}
						{_size(repeatableFields) > 0 && (<IconsListing pageTitle={pageTitle} fields={repeatableFields} text_color={textColor(select_background_color)} />)}
					</IconsWrapper>
				)}
				{(title || subtitle || button_name) && (
					<TitleWrapper>
						{title && (
							<H2 variant="heading" color={textColor(select_background_color)} textAlign="center">
								{title}
							</H2>
						)}
						{subtitle && (
							<P variant="subtitle" color={textColor(select_background_color)}>
								{subtitle}
							</P>
						)}
						{(button_name && button_link) && (
							<ButtonWrapper>
								<Button href={utils.linkResolver(button_link)} variant={buttonColor(select_background_color)}>
									{button_name}
								</Button>
							</ButtonWrapper>
						)}
					</TitleWrapper>
				)}
			</Wrapper>
		</Container>
	)
}

export default IconBanner2
