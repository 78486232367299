import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import _ from 'lodash'
import qs from 'query-string'
import { withPreview } from 'gatsby-source-prismic-graphql'
import styled from 'styled-components'


import Masonry from 'react-masonry-css'

import Container from '../Container'
import Card from '../Card'
import Button from '../Button'

import media from '../../styles/media'

const TagsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;

  > span {
    display: flex;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.31rem;
    line-height: 1.4;
    margin: auto;
    padding-bottom: 1rem;
    ${media.greaterThan('mobile')`
      margin: 0;
      margin-right: 1.5rem;
      padding-bottom: 0;
    `}
  }

  > button {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    ${media.greaterThan('mobile')`
      width: auto;
    `}
  }

  > button + button {
    ${media.greaterThan('mobile')`
      margin-left: 0.93rem;
    `}
  }
  ${media.greaterThan('mobile')`
    flex-direction: row;
  `}
`

const caseStudyDetailListingQuery = graphql`
  query caseStudyDetailListingQuery {
    case_study: prismic {
      allCase_studys {
        edges {
          node {
            ...case_studyFragment
            _meta {
              type
              uid
              tags
            }
          }
        }
      }
    }
  }
`

const CaseStudyDetailListing = ({ type, location }) => {
  const { filter } = qs.parse(location.search, { arrayFormat: 'comma' })

  let filters = []

  const getAllCards = (cardsArray) => {
    const getCardIsActive = (card) => {
      const tags = _.get(card, '_meta.tags')
      const matchingFilters = _.filter(tags, (tag) => _.indexOf(filters, tag) !== -1)
      if (_.size(tags)) {
        return filters.length === 0 || _.size(matchingFilters) < _.size(tags)
      } else {
        return true
      }

    }

    const getCards = (cardsObj) => {
      return (
        _
          .chain(_.get(cardsObj.resource, cardsObj.edgesString))
          .map((card) => _.get(card, cardsObj.nodeString))
          .map(card => ({ [cardsObj.type]: card, active: getCardIsActive(card) }))
          .value()
      )
    }

    const cards = _
      .chain(cardsArray)
      .map(getCards)
      .flatten()
      .sortBy((card) => {
        const { article, whitepaper, quiz } = card || {}
        if (article) { return article.publish_date }
        if (whitepaper) { return whitepaper.publish_date }
        if (quiz) { return quiz.quiz_publish_date }
      })
      .reverse()
      .value()

    return cards
  }

  return (
    <StaticQuery
      query={`${caseStudyDetailListingQuery}`}
      render={withPreview(({ case_study }) => {
        const caseStudyArray = [
          { type: 'case_study', resource: case_study, edgesString: 'allCase_studys.edges', nodeString: 'node', tagsString: '_meta.tags' },
        ]
        const cards = getAllCards(caseStudyArray)

        return (
          <Container type={type}>
            <Masonry
              breakpointCols={{
                default: 2,
                1200: 2,
                700: 1,
                500: 1,
              }}
              className="blog-masonry-grid"
              columnClassName="blog-masonry-grid_column"
            >
              {_.map(cards, (card, index) => {
                if (_.get(card, 'case_study')) { return <Card key={index} variant='case_study' {...card} /> }
              })}
            </Masonry>
          </Container>
        )
      }, caseStudyDetailListingQuery)}
    />
  )
}

export default CaseStudyDetailListing
