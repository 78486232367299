import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H2 from '../H2'
import CTA from '../CTA'
import media from '../../styles/media'
import utils from '../../utils'
import vars from '../../styles/vars'

const Wrapper = styled.div`
  max-width: ${vars.contentWidth.md}rem;
  margin: auto;
  text-align: ${props => props.textAlign};
  padding-bottom: 2rem;
  ${media.greaterThan('desktop')`
    max-width: ${vars.contentWidth.lg}rem;
    padding-bottom: 0;
  `}
`

const TitleWrapper = styled.div`
text-align: center;
`
const ButtonWrapper = styled.div`
  padding-top: 1.5rem;
`

const FreeTextPanel = (props) => {
  const {
    type,
    align_text,
    rich_text,
    cta_name,
    cta_title,
    title,
    modal_content,
    modal_footer,
    background_color
  } = props
  return (
    <Container type={type} noPadding={!title ? true : false} backgroundColor={background_color}>
      <TitleWrapper>
        <H2 variant='bigHeading'>{title}</H2>
      </TitleWrapper>
      <Wrapper textAlign={align_text}>
        <RichText render={rich_text} htmlSerializer={utils.htmlSerializer} />
        {cta_name && (
          <ButtonWrapper>
            <CTA
              name={cta_name}
              modalBody={{
                header: title,
                main: modal_content && <RichText render={modal_content} htmlSerializer={utils.htmlSerializer} />,
                footer: modal_footer && <RichText render={modal_footer} htmlSerializer={utils.htmlSerializer} />
              }}
            />
          </ButtonWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

export default FreeTextPanel