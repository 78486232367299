import React from 'react'
import styled from 'styled-components'

import Image from '../image'
import H3 from '../H3'
import P from '../P'
import Button from '../Button'
import Container from '../Container'

import media from '../../styles/media'

const BoxContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	${media.greaterThan('tablet')`
		flex-direction: ${({ flipPanels }) => (flipPanels ? 'row-reverse' : 'row')};
	`}
`

const Box = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: auto;
	:nth-of-type(odd) {
		padding-right: 0rem;
		padding-bottom: 2.5rem;
	}
	:nth-of-type(even) {
		padding-left: 0rem;
	}

	${media.greaterThan('tablet')`
		width: 50%;
		min-height: 42.875rem;
		:nth-of-type(odd) {
			padding-right: 1.25rem;
			padding-bottom: 0rem;
		}
		:nth-of-type(even) {
			padding-left: 1.25rem;
		}
	`}

	${media.greaterThan('desktop')`
		width: 50%;
		min-height: 42.875rem;
		:nth-of-type(odd) {
			padding-right: 2.5rem;
			padding-bottom: 0rem;
		}
		:nth-of-type(even) {
			padding-left: 2.5rem;
		}
	`}
`

const BoxContent = styled.div`
	height: 100%;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
	background: ${({ theme }) => theme.colors.black};
	${media.greaterThan('mobile')`
    padding-left: 3rem;
    padding-right: 3rem;
  `};
`
const ImageWrapper = styled.div`
	display: flex;
  img {
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	${media.greaterThan('mobile')`
    justify-content: flex-start;
  `};
`

const AuthorWrapper = styled.div`
	padding-top: 2rem;
	${media.greaterThan('mobile')`
    padding-top: 1.5rem;
  `};
`

const InvertedColumns = ({
	type,
	box_one_button_name,
	box_one_image,
	author,
	box_one_link_to_button,
	box_one_body,
	box_one_title,
	box_two_image,
	box_two_body,
	box_two_title,
	box_two_author,
	flipPanels
}) => {
	return (
		<Container type={type}>
			<BoxContainer flipPanels={flipPanels}>
				<Box>
					<BoxContent>
						<H3 variant="boxTitleLarge" textAlign="left" color={'light'}>
							{box_one_title}
						</H3>
						<P variant="secondary" color="light" textAlign="left">
							{box_one_body}
						</P>
						<AuthorWrapper>
							<P variant="primary" color="light" textAlign="left">
								{author}
							</P>
						</AuthorWrapper>
					</BoxContent>
					{box_one_link_to_button &&
						box_one_button_name && (
							<ButtonWrapper>
								<Button variant="secondary" onClick={box_one_link_to_button}>
									{box_one_button_name}
								</Button>
							</ButtonWrapper>
						)}
					<ImageWrapper><Image {...box_one_image} /></ImageWrapper>
				</Box>
				<Box>
					<ImageWrapper><Image {...box_two_image} /></ImageWrapper>
					<BoxContent>
						<H3 variant="boxTitleLarge" textAlign="left" color={'light'}>
							{box_two_title}
						</H3>
						<P variant="secondary" color="light" textAlign="left">
							{box_two_body}
						</P>
						<AuthorWrapper>
							<P variant="primary" color="light" textAlign="left">
								{box_two_author}
							</P>
						</AuthorWrapper>
					</BoxContent>
				</Box>
			</BoxContainer>
		</Container>
	)
}

export default InvertedColumns
