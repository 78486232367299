import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _size from 'lodash/size'
import styled from 'styled-components'
import media from './../../styles/media'

import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'
import P from '../P'
import Image from '../image'

import utils from '../../utils'

const TitleWrapper = styled.div`
	text-align: center;
	> p {
		max-width: 700px;
		margin: 10px auto;
	}
`
const SubtitleWrapper = styled.div`
	padding: 0;
	${media.greaterThan('mobile')`
    max-width: 820px;
    margin: auto;
  `};
`

const ItemsContainer = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	text-align: center;
	columns: 2;

	${media.greaterThan('mobile')`
		padding: 0;
		columns: 1;
  `};

	${media.greaterThan('tablet')`
    padding-left: 3rem;
    padding-right: 3rem;
  `};

	${media.greaterThan('desktop')`
    padding-left: 7rem;
    padding-right: 7rem;
  `};

	li {
		flex: 1 1 50%;
		padding-bottom: 1rem;
		${media.greaterThan('tablet')`
			flex: 1;
			width: 100%;
    `};

		${media.greaterThan('desktop')`
      flex: 1 1 ${({ size }) => 100 / size}%;
		`};
	}

	li:last-child {
		/* accounting for uneven columns */
		width: ${props => props.noItems % 2 === 0 ? '50%' : '25%'};
	}

	img {
		max-width: 200px;
		padding-bottom: 1rem;
		${media.greaterThan('mobile')`
		max-width: 150px;
  		`}
	}
`

const StyledLink = styled(Link)`
	&:hover {
		opacity: 0.7;
	}
`

const Item = ({ label, image, imageSharp }) => {
	return (
		<li key={label}>
			<Image {...image} {...imageSharp} />
			{label && <P variant="tertiary">{label}</P>}
		</li>
	)
}

const ItemLink = ({ label, image, imageSharp, page_link }) => (
	<li key={label}>
		<StyledLink key={label} to={utils.linkResolver(page_link)}>
			<Image {...image} {...imageSharp} />
			{label && <P variant="smallLabel">{label}</P>}
		</StyledLink>
	</li>
)

const ImagesPanel = ({ type, background_color, title, content, repeatableFields }) => {
	return (
		<Container type={type} backgroundColor={background_color}>
			<TitleWrapper>
				<H2 variant="heading" textAlign="center">
					{title}
				</H2>
				<SubtitleWrapper>
					<H3 variant="subtitle" textAlign="center">
						{_get(content, '0.text')}
					</H3>
				</SubtitleWrapper>
			</TitleWrapper>
			{repeatableFields.length !== 0 ?
				repeatableFields[0].label === null && repeatableFields[0].page_link === null && repeatableFields[0].image === null ? null : (
					<ItemsContainer noItems={repeatableFields.length} size={_size(repeatableFields)}>
						{_map(
							repeatableFields,
							(props, index) => (_get(props, 'page_link') ? <ItemLink key={index} {...props} /> : <Item key={index} {...props} />)
						)}
					</ItemsContainer>
				) : null
			}
		</Container>
	)
}

ImagesPanel.propTypes = {
	type: PropTypes.string,
	background_color: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.array,
	repeatableFields: PropTypes.array
}


export default ImagesPanel
