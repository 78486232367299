import React from 'react'
import styled from 'styled-components'

import Container from '../Container'
import H1 from '../H1'
import H2 from '../H2'
import H3 from '../H3'
import SignUpForm from '../Forms/SignUpForm'
import media from '../../styles/media'
import vars from '../../styles/vars'

const Wrapper = styled.div`width: 100%;`

const TitleWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	${media.greaterThan('mobile')`
    max-width: ${vars.contentWidth.lg}rem;
  `};
`

const SubTitleWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	${media.greaterThan('mobile')`
    max-width: ${vars.contentWidth.sm}rem;
  `};
`

const SignUpFormBanner = (props) => {
	const { type, title, text, hubspot_signup_form, hubspot_cta_title, hubspot_sign_up_label } = props || {}

	return (
		<Container type={type} isOverflowToTop backgroundColor={`dark`}>
			<Wrapper>
				<TitleWrapper>
					{props.pageTitle === 'Resources' || props.pageTitle === 'Case Study'? (
						<H1 variant="primary" color="light" textAlign="center">
							{title}
						</H1>
					) : (
						<H2 variant="heading" color="light" textAlign="center">
							{title}
						</H2>
					)
					}
				</TitleWrapper>
				<SubTitleWrapper>
					<H3 variant="subtitle" color="light" textAlign="center">
						{text}
					</H3>
				</SubTitleWrapper>
				{hubspot_signup_form && hubspot_sign_up_label && hubspot_cta_title && <SignUpForm FormLabel={hubspot_sign_up_label}CTATitle={hubspot_cta_title}/>}
			</Wrapper>
		</Container>
	)
}

export default SignUpFormBanner
