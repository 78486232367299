import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'

import Container from '../Container'
import H1 from '../H1'
import H2 from '../H2'
import H4 from '../H4'
import P from '../P'
import Image from '../Image'
import vars from '../../styles/vars'
import utils from '../../utils'
import media from '../../styles/media'
import theme from '../../styles/theme'
import Button from '../../components/Button'
import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'


const TopContentWrapper = styled.div`
	margin: 0 auto;
	text-align: center;
	h1, h2, h3, p {
		color: ${props => props.backgroundColor === 'dark' ? theme.colors.white : theme.colors.black}
	}
	${media.greaterThan('mobile')`
		max-width: 65rem;
		padding: 2rem 0;
	`} 
`
const SubtitleWrapper = styled.div`
	margin: auto;
	${media.greaterThan('mobile')`
		max-width: ${vars.contentWidth.md}rem;
	`}
`
const ContentWrapper = styled.div`
	margin: 0 auto;
	text-align: center;
	h1, h2, h3, p {
		color: ${props => props.backgroundColor === 'dark' ? theme.colors.white : theme.colors.black}
	}
	${media.greaterThan('mobile')`
    max-width: ${vars.contentWidth.md}rem;
  `} ${media.greaterThan('desktop')`
    max-width: ${vars.contentWidth.lg}rem;
  `};
`
const InnerContentWrapper = styled.div`
	padding: 0 1rem;
	${media.greaterThan('mobile')`
	padding: 0 2rem;
	`}
`
const ImageWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	> img {
		height: auto;
		width: 100%;
	}
	max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
	position: relative;
	top: -22px;
	${media.greaterThan('mobile')`
		top: -45px;
	`}
`
const ButtonWrapper = styled.div`
	padding: 2rem 0;
`
const ImageContainer = styled.div`
	position: relative;
`
const Panel = styled.div`
	background-color: ${theme.colors.white};
	padding: 1rem 0;
`
const BottomPanel = styled.div`
	position: relative;
`
const TitleContainer = styled.div`
max-width: 300px;
margin: auto;
${media.greaterThan('mobile')`
max-width: 100%
`}
`
const Arrow = styled.div`
width: 0;
height: 0;
margin: auto;
border-left: 177px solid transparent;
border-right: 177px solid transparent;
border-top: 41px solid #FFF;
${media.greaterThan('mobile')`
	border-top: 56px solid #FFF;
	border-left: 236px solid transparent;
	border-right: 236px solid transparent;
	position: relative;
	top: -1px;
`}
`
const ArrowContainer = styled.div`
	max-width: 330px;
	margin: auto;
	padding: 1rem 0;
	h4 {
		margin-bottom: .5rem;
		${media.greaterThan('mobile')`
			font-size: 1.5rem;
			margin-bottom: 1rem;
		`}
	}
	p {
		text-align: left;
		padding: 0 .5rem;
		${media.greaterThan('mobile')`
		padding: 0 2.3rem;
    font-size: 1.1rem;
    line-height: 1.6rem;
		`}
	}
	${media.greaterThan('mobile')`
		max-width: 525px;
		padding: 1rem 0;
	`}
`
const LargeImgWithOverlapBanner = ({
	type,
	background_color = 'dark',
	arrow_img,
	bottom_subtitle,
	bottom_title,
	cta_link,
	cta_name,
	second_subtitle,
	second_title,
	subtitle,
	title,
	first_arrow_title,
	first_arrow_text,
	is_top_content
}) => {
	const textColor = background_color === 'dark' ? theme.colors.white : theme.colors.black
	return (
		<Container 
			type={type} 
			fullWidth 
			isOverflowToTop={_isNull(is_top_content) || _isUndefined(is_top_content) ? true : is_top_content}
			backgroundColor={background_color} 
			fullSectionWidth
			noTopPadding={ !is_top_content }			
		>
			<ImageContainer>
				{title && 
				<TopContentWrapper backgroundColor={background_color}>
					<H1 variant='primary' color={textColor}>
						{title}
					</H1>
					<SubtitleWrapper>
						<H2 variant='primary' color={textColor}>
							{subtitle}
						</H2>
					</SubtitleWrapper>
				</TopContentWrapper>}
				<Panel>
					<ContentWrapper backgroundColor={'light'}>
						<TitleContainer>
							<H2 variant='heading' color={textColor}>
								{second_title}
							</H2>
						</TitleContainer>
						<InnerContentWrapper>
							<H2 as='h3' variant='primary' color={textColor}>
								{second_subtitle}
							</H2>
						</InnerContentWrapper>
						<ArrowContainer>
							<H4 variant='primary'>{first_arrow_title}</H4>
							<P variant='arrowBody'>{first_arrow_text}</P>
						</ArrowContainer>
					</ContentWrapper>
				</Panel>
				<Arrow />
				{/* All images are provided double the actual design size.
        	Therefore, the max-width is set to half the image width. */}
				{arrow_img && (
					<ImageWrapper maxWidth={_get(arrow_img, 'dimensions.width') / 2}>
						<Image {...arrow_img} />
					</ImageWrapper>
				)}
				<BottomPanel>
					<ContentWrapper backgroundColor={background_color}>
						<H2 variant='heading' color={textColor}>
							{bottom_title}
						</H2>
						<H2 as='h3' variant='primary' color={textColor}>
							{bottom_subtitle}
						</H2>
						<ButtonWrapper>
							<Button variant='secondary' href={utils.linkResolver(cta_link)}>{cta_name}</Button>
						</ButtonWrapper>
					</ContentWrapper>
				</BottomPanel>
			</ImageContainer>
		</Container>
	)
}

export default LargeImgWithOverlapBanner
