import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Container from '../Container'
import Box from '../Box'
import media from '../../styles/media'

const BoxesContainer = styled.div`
	display: flex;
	flex-direction: column;
	> div + div {
		margin-top: 2.5rem;
		margin-left: 0;
	}

	${media.greaterThan('tablet')`
		flex-direction: row;
    > div + div {
			margin-top: 0;
			margin-left: 2.5rem;
		}
  `};
`

const InvertedBoxesPanel = ({
	type,
	colored_box_button,
	colored_box_paragraph,
	colored_box_link,
	colored_box_title,
	light_box_title,
	light_box_paragraph,
	arrow_button_name,
	arrow_button_link
}) => {
	return (
		<Container type={type}>
			<BoxesContainer>
				<Box
					backgroundColor={'dark'}
					textColor={'light'}
					title={colored_box_title}
					text={colored_box_paragraph}
					link={colored_box_link}
					buttonText={colored_box_button}
					buttonVariant={'primary'}
					buttonAlign={'left'}
				/>
				<Box
					backgroundColor={'none'}
					textColor={'dark'}
					title={light_box_title}
					text={light_box_paragraph}
					link={arrow_button_link}
					buttonText={arrow_button_name}
					buttonVariant={'arrowButton'}
					buttonAlign={'right'}
				/>
			</BoxesContainer>
		</Container>
	)
}

export default InvertedBoxesPanel
