import React, { useCallback, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'

import Image from '../../../image'
import Container from '../../../Container'
import media from '../../../../styles/media'

// import background from "./assets/background.svg"
import texture from "./assets/texture.png"
import sofa from "./assets/sofa.png"
// import fv_logo from "./assets/fv_logo.png"
import play_button from "./assets/play_button.png"

const InnerContainer = styled.div`
  margin-bottom: 7.7rem; /* important */
  width: 100%;
  min-height: 308px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  ${media.greaterThan("tablet")`
    flex-direction: row;
  `}
  ${media.greaterThan('desktop')`
    flex-direction: row;
  `}
`

const SofaImage = styled.img`
  z-index: 0;
  width: 565px;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: unset;
  transform: translate(-50%, 0);
  ${media.greaterThan("tablet")`
    width: 600px;
    right: 0;
    left: unset;
    transform: none;
  `}
  ${media.greaterThan("desktop")`
    width: 800px;
    right: 0;
    left: unset;
    transform: none;
  `}
`

const LogoImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 25px;
  display: none;
  ${media.greaterThan("tablet")`
    display: block;
  `}
`
const TextContainer = styled.div`
  z-index: 2;
  > h2 {    
    max-width: 550px;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 48px;
    line-height: 2.5rem;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 43px;
    text-align: center;
  }

  > p {
    max-width: 400px;
    color: #FFFFFF;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: Helvetica;
    font-size: 17px;
    line-height: 1.7;
    text-shadow: 0 2px 4px rgba(0,0,0,0.20);
    letter-spacing: 0;
    text-align: center;
    margin: 0 auto 75px auto;
  }

  ${media.greaterThan("tablet")`
    > h2 { text-align: left; margin-bottom: 43px; }
    > p { text-align: left; margin: 0 0 75px 0; }
  `}
  ${media.greaterThan('desktop')`
    > h2 { text-align: left; }
    > p { text-align: left; }
  `}
`

const VideoContainer = styled.div`
  width: 361px;
  height: 202px;
  position: relative;
  bottom: 25px;
  left: unset;
  transform: none;
  z-index: 1;
  > iframe {
    z-index: 2;
    top:0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  > img {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  ${media.greaterThan("tablet")`
    width: 351px;
    height: 200px;
    bottom: -28px;
    right: 130px;
    left: unset;
    transform: none;
  `}
  ${media.greaterThan('desktop')`
    width: 461px;
    height: 258px;
    bottom: 10px;
    right: 180px;
  `}
`

const PlayButton = styled.button`
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) }
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 123px;
  > img {
    width: 100%;
  }
  ${media.greaterThan('tablet')`
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const PromoBannerFurnitureVillage = ({
  type,
  title,
  subtitle,
  vimeo_video_id,
  video_thumbnail
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayVideo = useCallback(() => {
    setIsPlaying(true)
  })
	return (
		<Container
			type={type}
      noPadding
      backgroundColor={'custom'}
      customBackground={`url(${texture}), linear-gradient(180deg, #34B8C7 0%, #ECFDFF 100%)`}
      backgroundBlendMode={'screen'}
		>
      <InnerContainer>
        <TextContainer>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </TextContainer>
        {/* {fv_logo && <LogoImage src={fv_logo} />} */}
        <VideoContainer>
          {((vimeo_video_id && isPlaying) || !video_thumbnail) && <iframe src={`https://player.vimeo.com/video/${vimeo_video_id}${video_thumbnail ? '&autoplay=1' : ''}`} width="604" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>}
          {video_thumbnail && <Image {...video_thumbnail} alt={''}/>}
          {(!isPlaying && video_thumbnail) && <PlayButton onClick={handlePlayVideo}>
            {play_button && <img src={play_button} />}
          </PlayButton>}
        </VideoContainer>
        {sofa && <SofaImage src={sofa} />}
      </InnerContainer>
		</Container>
	)
}

export default PromoBannerFurnitureVillage
