import React from 'react'
import { graphql } from 'gatsby'
import _map from 'lodash/map'
import styled from 'styled-components'
import media from '../../styles/media'
import Box from '../Box'
import Container from '../Container'

const BoxesContainer = styled.div`
	display: flex;
	flex-direction: row;
	> div + div {
		margin-left: 2.5rem;
	}
	${media.lessThan('tablet')`
    flex-direction: column;
    > div + div {
      margin-left: 0;
      margin-top: 2.5rem;
    }
  `};
`

const InvertedColoredBoxesPanel = ({ type, repeatableFields }) => {
	return (
		<Container type={type}>
			<BoxesContainer>
				{_map(repeatableFields, (field, index) => {
					const { background_color, box_title, box_body, button_name, link, ga_tracking_label } = field || {}
					return (
						<Box
							key={index}
							backgroundColor={background_color === 'Dark' ? 'dark' : 'light'}
							textColor={background_color === 'Dark' ? 'light' : 'dark'}
							title={box_title}
							text={box_body}
							link={link}
							trackingLabel={ga_tracking_label}
							buttonText={button_name}
							buttonVariant={'primary'}
							buttonAlign={'left'}
						/>
					)
				})}
			</BoxesContainer>
		</Container>
	)
}

export default InvertedColoredBoxesPanel
