import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import Image from '../Image'
import Button from '../Button'

import utils from '../../utils'

import media from '../../styles/media'

const ContentWrapper = styled.div`
	text-align: center;

	> * {
		max-width: 56.25rem;
		margin: 0 auto;
	}

	p {
		max-width: 34.375rem;
	}
`

const ImageWrapper = styled.div`
	max-width: 426px;
	position: absolute;
	bottom: -165px;
	> img {
		width: 100%;
	}
	${media.lessThan('desktop')`
    display: none;
  `};
`
const LeftImageWrapper = styled(ImageWrapper)`
  left: 0;
`
const RightImageWrapper = styled(ImageWrapper)`
  right: 0;
`
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding: 2.6rem 0;
	${media.greaterThan('mobile')`
		padding: 4.6rem 0;
	`}
`

const LargeContentWithSideImages = ({ content, left_image, right_image, button_text, button_link }) => {
	return (
		<Container fullWidth fullSectionWidth noPadding backgroundColor={`light`}>
			<ContentWrapper>
				<LeftImageWrapper>
					<Image {...left_image} />
				</LeftImageWrapper>
				<RichText render={content} htmlSerializer={utils.htmlSerializer} />
				{button_text &&
				button_link && (
					<ButtonWrapper>
						<Button href={utils.linkResolver(button_link)} variant="primary">
							{button_text}
						</Button>
					</ButtonWrapper>
				)}
				<RightImageWrapper>
					<Image {...right_image} />
				</RightImageWrapper>
			</ContentWrapper>
		</Container>
	)
}

export default LargeContentWithSideImages
