import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H2 from '../H2'
import CTA from '../CTA'
import media from '../../styles/media'
import utils from '../../utils'

const Wrapper = styled.div`
  text-align: ${props => props.textAlign};
  padding-bottom: 1rem;
  ${media.greaterThan('mobile')`
    padding-bottom: 2rem;
  `}
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  display: flex;
  align-items: center;
  ${media.greaterThan('mobile')`
    padding: 13rem 9rem 3rem 9rem;
  `}
  ${media.greaterThan('desktop')`
    padding: 16rem 17rem 3rem 17rem;
  `}
`

const TitleWrapper = styled.div`
text-align: center;
`
const ButtonWrapper = styled.div`
`
const BackgroundImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: url(${(props) => props.backgroundImage.url}) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

const BackgroundImagePanel = (props) => {
  const {
    background_image,
    body_text,
    align_text,
    cta_link,
    cta_name,
    modal_content,
    modal_footer,
    title,
  } = props
  return (
    <BackgroundImageWrapper backgroundImage={background_image}>
      <Container noPadding>
        <InnerWrapper>
          <TitleWrapper>
            <H2 variant='bigHeading'>{title}</H2>
          </TitleWrapper>
          <Wrapper textAlign={align_text}>
            <RichText render={body_text} htmlSerializer={utils.htmlSerializer} />
          </Wrapper>
          {cta_name && (
            <ButtonWrapper>
              <CTA
                variant='primaryGreen'
                onClick={cta_link}
                name={cta_name}
                modalBody={{
                  header: title,
                  main: modal_content && <RichText render={modal_content} htmlSerializer={utils.htmlSerializer} />,
                  footer: modal_footer && <RichText render={modal_footer} htmlSerializer={utils.htmlSerializer} />
                }}
              />
            </ButtonWrapper>
          )}
        </InnerWrapper>
      </Container>
    </BackgroundImageWrapper>
  )
}

export default BackgroundImagePanel