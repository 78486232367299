import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import _get from 'lodash/get'

import Container from '../Container'
import H2 from '../H2'
import Image from '../Image'
import vars from '../../styles/vars'
import utils from '../../utils'
import media from '../../styles/media'
import theme from '../../styles/theme'

const ContentWrapper = styled.div`
	margin: 0 auto;
	text-align: ${props => props.textAlign};
	h2, p {
		color: ${props => props.backgroundColor === 'dark' ? theme.colors.white : theme.colors.black}
	}
	${media.greaterThan('mobile')`
    max-width: ${vars.contentWidth.md}rem;
  `} ${media.greaterThan('desktop')`
    max-width: ${vars.contentWidth.lg}rem;
  `};
`
const SubHeading = styled(H2)`
	max-width: 836px;
	margin-bottom: 0px;
	${media.lessThan('desktop')`
	margin-bottom: 1rem;
	`}
	${media.lessThan('tablet')`
	text-align: center;
	`}
`

const SecondaryContentWrapper = styled(ContentWrapper)`
	margin-top: 4rem;
`

const ParagraphContentText = styled.div`
	padding: 0px;
	> p {
		padding-right: 20px;
	}
	${media.lessThan('tablet')`
	> p {
		padding-right: 10px;
		padding-bottom: 5px;
	}
	`}
`

const SubParagraphContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	margin-bottom: 2rem;
	${media.lessThan('tablet')`
	flex-direction: column;
	`}
`

const SubParagraphContentText = styled.div`
	width: 55%;
	padding: 0px;
	> p {
		padding-bottom: 0px;
	}
	${media.lessThan('tablet')`
	width: 100%;
	margin-bottom: 2rem;
	`}
`

const ImageWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	> img {
		height: auto;
		width: 100%;
	}
	max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
`
const ParagraphImageWrapper = styled(ImageWrapper)`
${media.lessThan('tablet')`
padding: 10px 0;
width: 50%;
`}
`

const LargeImagePanel = ({
	type,
	align_heading,
	align_text,
	background_color,
	image,
	paragraph,
	paragraph_image,
	secondary_paragraph,
	secondary_title,
	sub_heading,
	title
}) => {
	const textColor = background_color === 'dark' ? theme.colors.white : theme.colors.black
	return (
		<Container type={type} backgroundColor={background_color}>
			<ContentWrapper textAlign={align_text} backgroundColor={background_color}>
				<H2 variant="heading" textAlign={align_heading} color={textColor}>
					{title}
				</H2>
				{sub_heading && <SubHeading variant="heading" textAlign={align_text} color={textColor}>
					{sub_heading}
				</SubHeading>}
				<SubParagraphContentWrapper>
					{!paragraph_image ?
						<ParagraphContentText>
							<RichText render={paragraph} htmlSerializer={utils.htmlSerializer} />
						</ParagraphContentText>
						: null}
					{paragraph_image ?
						<>
							<SubParagraphContentText>
								<RichText render={paragraph} htmlSerializer={utils.htmlSerializer} />
							</SubParagraphContentText>
							<ParagraphImageWrapper maxWidth={_get(paragraph_image, 'dimensions.width') / 2}>
								<Image {...paragraph_image} />
							</ParagraphImageWrapper>
						</>
						: null
					}
				</SubParagraphContentWrapper>
			</ContentWrapper>
			{/* All images are provided double the actual design size.
        	Therefore, the max-width is set to half the image width. */}
			{image && (
				<ImageWrapper maxWidth={_get(image, 'dimensions.width') / 2}>
					<Image {...image} />
				</ImageWrapper>
			)}
			{(secondary_title || (secondary_paragraph && secondary_paragraph[0].text !== '')) && (<SecondaryContentWrapper textAlign={align_text} backgroundColor={background_color}>
				<H2 variant="heading" textAlign={align_text} color={textColor}>
					{secondary_title}
				</H2>
				<ParagraphContentText>
					<RichText render={secondary_paragraph} htmlSerializer={utils.htmlSerializer} />
				</ParagraphContentText>
			</SecondaryContentWrapper>
			)}
		</Container>
	)
}

export default LargeImagePanel
