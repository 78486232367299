import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Container from '../Container'
import H1 from '../H1'
import H2 from '../H2'
import P from '../P'
import Button from '../Button'

import utils from './../../utils'
import media from '../../styles/media'

const TitleWrapper = styled.div`
	text-align: center;
	margin: 0 auto;
	padding: 3rem 0;
	max-width: 63.187rem;
`
const SubTitleWrapper = styled.div`
	padding-left: 4rem;
	padding-right: 4rem;
`
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 4.375rem;
`

const ImageWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 11rem;
	${media.lessThan('tablet')`
    display: none;
  `};
`

const CornerImage = styled.img`
	background-size: contain;
	position: absolute;
	bottom: 0;
	right: 0;
`

const HeroWithButton = ({ type, button_name, button_link, banner_title, banner_corner_image, subtitle, text }) => {
	return (
		<Container type={type} fullWidth fullSectionWidth isOverflowToTop noPadding backgroundColor={'dark'}>
			<TitleWrapper>
				<H1 variant="primary" color="light">
					{banner_title}
				</H1>
				<SubTitleWrapper>
					<H2 variant="paragraph" color="light">
						{subtitle}
					</H2>
					<P variant="primary" color="light">
						{text}
					</P>
					{button_link && (
						<ButtonWrapper>
							<Button href={utils.linkResolver(button_link)} variant="secondary">
								{button_name}
							</Button>
						</ButtonWrapper>
					)}
				</SubTitleWrapper>
			</TitleWrapper>
			{banner_corner_image && (
				<ImageWrapper>
					<CornerImage src={banner_corner_image.url} alt="" />
				</ImageWrapper>
			)}
		</Container>
	)
}

export default HeroWithButton
