import styled from 'styled-components'
import { variant } from 'styled-system'

import theme from '../styles/theme'

const H3Variant = styled('h3')(
  variant({
    variants: {
      primary: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.187rem', '1.187rem', '1.375rem'],
        lineHeight: '2.125rem',
        letterSpacing: '0.0287rem'
      },
      secondary: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.187rem', '1.187rem', '1.375rem'],
        lineHeight: '2.125rem',
        letterSpacing: '0.0287rem'
      },
      smallHeading: {
        fontSize: '1.0625rem',
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      },
      tinyHeading: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['0.937rem', '0.937rem', '0.937rem'],
        lineHeight: '1.66',
        letterSpacing: '0'
      },
      subtitle: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.187rem', '1.187rem', '1.375rem'],
        lineHeight: '2.125rem',
        letterSpacing: '0.0287rem'
      },
      boxTitle: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.470rem',
        lineHeight: 1.26
      },
      boxTitleSmall: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.6rem',
        lineHeight: '.5rem'
      },
      boxTitleLarge: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.937rem', '1.937rem', '2.312rem'],
        lineHeight: '2.065rem'
      },
      linkTitle: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.3rem',
        lineHeight: 1.2
      },
      richTextRoboto: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.375rem',
        lineHeight: '2.125rem',
        letterSpacing: '0.0287rem'
      },
      richTextFutura: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.3125rem',
        lineHeight: 1.26
      },
      listingTitle: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: "1.3127rem",
        lineHeight: 1.19
      },
    }
  })
)

const H3 = styled(H3Variant)`
  color: ${props => props.color === 'light' ? theme.colors.white : theme.colors.dark};
  text-align: ${props => props.textAlign};
  margin-bottom: 1rem;
`

export default H3
