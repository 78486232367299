import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H3 from '../H3'
import P from '../P'
import Image from '../Image'
import Button from '../Button'

import media from '../../styles/media'
import utils from '../../utils'
import vars from '../../styles/vars'

const Box = styled.div`
	display: flex;
	margin: 0 auto;
	flex-direction: row;
	max-width: ${vars.contentWidth.lg}rem;
	${media.lessThan('tablet')`
    flex-direction: column;
    flex: 1;
  `};
`
const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background: ${(props) => props.theme.colors.tertiary};
	box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.1);
	padding: 1.5rem;
	justify-content: center;
	> * {
		font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
			"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	}
	${media.greaterThan('mobile')`
		padding: 3rem;
		padding-top: 4rem;
	`};
	${media.greaterThan('tablet')`
    padding-left: 4rem;
    padding-right: 6rem;
	`};
`
const ImageWrapper = styled.div`
	${media.lessThan('tablet')`
    > img { width: 100%; }
  `};
`
const ButtonWrapper = styled.div``
const ParagraphWrapper = styled.div`padding-bottom: 2rem;`
const RichTextWrapper = styled.div`
	p {
		font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
			"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	}
	padding-top: .5rem;
`

const ImageTextPanel = (props) => {
	const { type, title, paragraph, image, buttonName, buttonLink, author } = props
	return (
		<Container type={type}>
			<Box>
				<TextWrapper>
					<H3 variant="boxTitleLarge" textAlign="left" color={'light'}>
						{title}
					</H3>
					<ParagraphWrapper>
						<RichText render={paragraph} htmlSerializer={utils.htmlSerializer} />
						<P variant="primary" color={'light'}>
							{author}
						</P>
					</ParagraphWrapper>
					{buttonName && buttonLink ? (
						<ButtonWrapper>
							<Button variant="secondary" onClick={buttonLink}>
								{buttonName}
							</Button>
						</ButtonWrapper>
					) : null}
				</TextWrapper>
				<ImageWrapper>
					<Image {...image} />
				</ImageWrapper>
			</Box>
		</Container>
	)
}

export default ImageTextPanel
