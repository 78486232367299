import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import { RichText } from 'prismic-reactjs'

import Container from '../Container'
import H2 from '../H2'
import Image from '../Image'

import vars from '../../styles/vars'
import utils from '../../utils'

const TitleWrapper = styled.div`
	max-width: ${vars.contentWidth.lg}rem;
	margin: 0 auto;
	padding-bottom: 1.2rem;
	${media.lessThan('tablet')``};
`

const ImageWrapper = styled.div`
	position: relative;
	margin: 0 auto;
	height: 20rem;
	margin-bottom: 1.5rem;
	> img {
		margin-bottom: 1.5rem;
		width: auto;
		height: auto;
		max-width: 1600px;
		max-height: 25rem;
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
	}
`

const FullWidthImagePanel = ({ type, id, background_color, title, image, body1 }) => {
	return (
		<Container type={type} backgroundColor={'light'} fullWidth>
			<TitleWrapper>
				<H2 variant="heading" textAlign="left">
					{title}
				</H2>
				<RichText render={body1} htmlSerializer={utils.htmlSerializer} />
			</TitleWrapper>
			<ImageWrapper>
				<Image {...image} />
			</ImageWrapper>
		</Container>
	)
}

export default FullWidthImagePanel
