import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import _get from "lodash/get"

import media from "../styles/media"
import useWindowSize from '../hooks/useWindowSize'
import vars from '../styles/vars'
import utils from '../utils'

const ImgWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;
${media.greaterThan('mobile')`
  padding-bottom: 3.125rem;
`}
`

const ImgCover = styled.div`
  width: 100%;
  height: 22.5rem;
  display: block;
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 1.875rem;
`

const RoundedImgCard = (props) => {
  const { link, quiz_resource_card_image, mobile_quiz_image, show_quiz_card, quiz_link } = props.quiz
  const windowSize = useWindowSize()
  const imageUrl = windowSize < vars.breakpoints.mobile * 16 && mobile_quiz_image ? _get(mobile_quiz_image, "url") : _get(quiz_resource_card_image, "url")
  return (
    <>
      {show_quiz_card && (
        <Link to={utils.linkResolver(quiz_link)}>
          <ImgWrapper>
            <ImgCover url={imageUrl} />
          </ImgWrapper>
        </Link>
      )}
    </>
  )
}

export default RoundedImgCard