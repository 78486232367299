import React from 'react'
import styled from 'styled-components'
import _map from 'lodash/map'

import Image from '../image'
import media from '../../styles/media'
import Container from '../Container'
import H2 from '../H2'
import P from '../P'


const IconsContainer = styled.div`
  display: flex;
  ${media.greaterThan('desktop')`
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 2rem;
  `}
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 1rem;
`
const IconParent = styled.div`
  flex-direction: column;
  align-items: center;
  flex: 1 0 26%;
  text-align: center;
  padding: .37rem;
  ${media.greaterThan('desktop')`
  flex: 1;
`}

`
const ImageWrapper = styled.div`

`
const InnerWrapper = styled.div`
${media.greaterThan('mobile')`
  padding-left: 4rem;
  padding-right: 4rem;
`}
`

const LogosPanel = (props) => {
  const { type, title, subtitle, background_color, repeatableFields } = props
  return (
    <Container type={type} backgroundColor={background_color}>
      <InnerWrapper>
        <H2 variant='heading' textAlign='center'>{title}</H2>
        <P variant='subtitle' textAlign='center'>{subtitle}</P>
        <IconsContainer>
          {_map(repeatableFields, ({ logo_image }, index) => (
            <IconParent key={index}>
              <ImageWrapper>
                <Image {...logo_image} />
              </ImageWrapper>
            </IconParent>
          ))}
        </IconsContainer>
      </InnerWrapper>
    </Container>
  )
}

export default LogosPanel