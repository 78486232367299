import React from 'react'
import Img from 'gatsby-image'


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `gatsby-source-prismic-graphql`: https://www.gatsbyjs.org/packages/gatsby-source-prismic-graphql
 */

const Image = (props) => {
  const { alt, url, dimensions, width } = props || {}
  // const sharpImage = _.get(childImageSharp, 'fluid')
  const sharpImage = false
  return sharpImage
    ? (<Img fluid={sharpImage} {...width}/>)
    : (<img src={url} alt={alt} {...dimensions} {...width} />)
}

export default Image
