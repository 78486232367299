import styled from 'styled-components'
import { variant } from 'styled-system'
import media from 'styled-media-query'

import theme from '../styles/theme'

const H2Variant = styled('h2')(
	variant({
		variants: {
			primary: {
				fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.187rem', '1.187rem', '1.375rem'],
				lineHeight: [1.33, 1.33, 1.63]
			},
			secondary: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
			},
			heading: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.937rem', '1.937rem', '3.5rem', '3.75rem'],
				lineHeight: [1.25, 1.25, 1.25, 1.25]
			},
			subHeading: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: '1.625rem',
				lineHeight: 1.26
			},
			bigHeading: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.937rem', '1.937rem', '3rem'],
				lineHeight: 1.16
			},
			smallHeading: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.187rem', '1.187rem', '1.375rem'],
				lineHeight: 1.63
			},
			subtitle: {
				fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.187rem', '1.187rem', '1.375rem'],
				lineHeight: 1.63
			},
			cardtitle: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: '1.0625rem',
				lineHeight: 1.17
			},
			paragraph: {
				fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: '1.375rem',
				letterSpacing: '0.02875rem',
				lineHeight: 1.17
			},
			richTextRoboto: {
				fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: ['1.187rem', '1.187rem', '1.375rem'],
				lineHeight: [1.33, 1.33, 1.63]
			},
			richTextFutura: {
				fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
			},
		}
	})
)

const H2 = styled(H2Variant)`
  color: ${(props) => (props.color === 'light' ? theme.colors.white : theme.colors.dark)};
  text-align: ${(props) => props.textAlign};
  ${media.lessThan('medium')``}
  ${media.greaterThan('large')``}
`

export default H2
