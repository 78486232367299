import { registerLinkResolver }  from 'gatsby-source-prismic-graphql'

import AppProvider from './src/AppProvider'
import utils from './src/utils/'
import './src/styles/global.css'

registerLinkResolver(utils.linkResolver)

export const onRouteUpdate = ({ location, prevLocation }) => {  
  const doNotTrackHS = document.cookie.split(';').filter((item) => {
    return (item.trim().indexOf('__hs_do_not_track=') === 0)
  }).length

  const doNotTrackLF = document.cookie.split(';').filter((item) => {
    return (item.trim().indexOf('__lf_do_not_track=') === 0)
  }).length

  // opted in, create hubspot script
  if (!doNotTrackHS && process.env.GATSBY_ENV === 'production' && !document.getElementById('hs-script-loader')) {
    let script = document.createElement('script')
    script.type = `text/javascript`
    script.src = `//js.hs-scripts.com/${process.env.GATSBY_HUBSPOT_PORTAL_ID}.js`
    script.id = `hs-script-loader`
    script.async = true
    document.body.appendChild(script)
  }

  // opted in, create leadforensics script
  if (!doNotTrackLF && process.env.GATSBY_ENV === 'production' && !document.getElementById('lf-script-loader')) {
    let script = document.createElement('script')
    script.type = `text/javascript`
    script.src = `https://secure.leadforensics.com/js/117798.js`
    script.id = `lf-script-loader`
    script.async = true

    let lfImg = document.createElement('img')
    lfImg.alt = ""
    lfImg.src = 'https://secure.leadforensics.com/117798.png'
    lfImg.style = { display: 'none' }
    
    let noscript = document.createElement('noscript')
    noscript.appendChild(lfImg)
    document.body.appendChild(script)
    document.body.appendChild(noscript)
  }
}

export const wrapRootElement = AppProvider