import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import _map from "lodash/map"

import H2 from '../H2'
import H3 from '../H3'
import H4 from '../H4'
import P from '../P'

import Container from '../Container'
import Button from '../Button'
import CTA from '../CTA'

import theme from '../../styles/theme'
import media from '../../styles/media'
import vars from '../../styles/vars'

import utils from '../../utils'

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div + div { margin-top: 30px; }
`
const TextContainer = styled.div`
  width: 100%;
  max-width: ${vars.contentWidth.lg}rem;
  padding: 1rem;
  background-color: ${theme.colors.light};

  ${media.greaterThan('mobile')`
    padding: 1rem 2.5rem;
  `}

  ${media.greaterThan('desktop')`
    padding: 1.5rem 3rem;
  `}
`
const HeaderWrapper = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;
  max-width: ${vars.contentWidth.lg}rem;
  margin: auto;
`

const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 1.5rem;
  justify-content: center;
  ${media.greaterThan('mobile')`
    justify-content: flex-start;
  `}
`
const MessageWrapper = styled.div`
  padding: 2rem;
  h3 {
    margin-bottom: 0;
  }
`
const ListOfCTAs = (props) => {
  const {
    type,
    no_items_left_message,
    subtitle,
    title,
    repeatableFields,
  } = props
  return (
    <Container
      type={type}
      noBottomPadding>
      <HeaderWrapper>
        <H2 variant='bigHeading'>{title}</H2>
        <H3 variant='primary'>{subtitle}</H3>
      </HeaderWrapper>
      <SectionWrapper>
        {repeatableFields.length === 0 ? (
          <MessageWrapper>
            <H3 variant="boxTitle">{no_items_left_message}</H3>
          </MessageWrapper>
        ) : (
            _map(repeatableFields, (field) => {
              const {
                cta_name,
                section_body,
                section_title,
                cta_link,
                modal_content,
                modal_footer
              } = field
              return (
                <TextContainer>
                  <H4 variant='secondary'>{section_title}</H4>
                  <P variant='primary'>{section_body}</P>
                  <ButtonWrapper>
                    <CTA
                      onClick={cta_link}
                      name={cta_name}
                      modalBody={{
                        header: section_title,
                        main: modal_content && <RichText render={modal_content} htmlSerializer={utils.htmlSerializer} />,
                        footer: modal_footer && <RichText render={modal_footer} htmlSerializer={utils.htmlSerializer} />
                      }}
                    />
                  </ButtonWrapper>
                </TextContainer>
              )
            })
          )}
      </SectionWrapper>
    </Container>
  )
}

export default ListOfCTAs