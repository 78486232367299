import React from 'react'
import _size from 'lodash/size'
import _map from 'lodash/map'
import styled from 'styled-components'

import Container from '../Container'
import H2 from '../H2'
import P from '../P'

import media from '../../styles/media'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.greaterThan('tablet')`
    flex-direction: row;
  `}
  ${media.greaterThan('desktop')`
    flex-direction: row;
  `}

`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 145px;
  width: 145px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  margin: 15px;
  > * { color: ${({ color }) => color}; }
`

const RetailersPanel = ({ type, title, repeatableFields }) => {
  return (
    <Container type={type} centerContent backgroundColor={'light'}>
      <H2 variant="subHeading">{title}</H2>
      {_size(repeatableFields) && (
        <Wrapper>
          {_map(repeatableFields,
            ({
              retailer_text_color,
              retailer_background_color,
              retailer_name
            }) => <Circle
              color={retailer_text_color}
              backgroundColor={retailer_background_color}>
                <P variant="largeLabel">{retailer_name}</P>
              </Circle>)}
        </Wrapper>
      )}

    </Container>
  )
}

export default RetailersPanel
