import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from '../../styles/media'
import { RichText } from 'prismic-reactjs'
import Button from '../Button'
import Container from '../Container'
import H2 from '../H2'
import Image from '../Image'

import vars from '../../styles/vars'
import utils from '../../utils'

const InnerContainer = styled.div`
  width: 100%;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  background-color: #000;
  background-image: url("${({ backgroundImage }) => backgroundImage.url}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: screen;

  > img {
    width: 306px;
    margin-bottom: 48px;
    padding-top: 50px;
  }

  > h2 {
    max-width: 955px;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 2.0625rem;
    line-height: 2.5rem;
    color: #000000;
    margin-bottom: 43px;
    text-align: center;
  }
  > span {
    color: #FFFFFF;
    font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 8px;
  }

  > p {
    max-width: 887px;
    color: #000000;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.437rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    margin-bottom: 75px;
    text-align: center;
  }

  ${media.greaterThan("tablet")`
    
  `}
`
const ButtonWrapper = styled.div`
	text-align: center;
`

const TitleImageButton = ({ type, button_text, button_url, description, title, image }) => {
	return (
		<Container
			fullWidth
			fullSectionWidth
			noPadding
			noBottomPadding
		>
      <InnerContainer backgroundImage={image}>
        <h2>{title}</h2>
		<p>{description}</p>
		{button_text && button_url ? (
			<ButtonWrapper>
				<Button href={utils.linkResolver(button_url)} style={{backgroundColor: "red", color: "white"}} variant='heroButton' onClick={button_url}>
					{button_text}
				</Button>
			</ButtonWrapper>
		) : null}
      </InnerContainer>
		</Container>
	)
}

export default TitleImageButton
