import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _replace from 'lodash/replace'

import media from '../../styles/media'

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 750px;
	margin: auto;
	text-align: center;
`

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	max-width: 518px;
	${media.greaterThan('mobile')`
		flex-direction: row;
	`}
`

const StyledLabel = styled.label`
	display: flex;
	justify-content: center;
	padding-bottom: 1rem;
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.187rem;
	line-height: 1.4;
	vertical-align: top;
	color: ${(props) => props.theme.colors.white};
`

const StyledErrorMessage = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	color: red;
`

const StyledInputField = styled.input`
	height: 55px;
	width: 100%;
	padding: 0 25px;
	font-size: 1rem;
	line-height: 1.5rem;
	opacity: .8;
	background-color: ${(props) => props.theme.colors.white};
	color: ${(props) => props.theme.colors.darkSecondary};
	border: none;
`

const SubmitButton = styled.button`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.125rem;
	height: 55px;
	width: 100%;
	padding: 0 10px;
	font-size: 1rem;
	line-height: .8;
	color: ${(props) => props.theme.colors.white};
	background-color: ${(props) => props.theme.colors.greyBlue};
	border: none;
	cursor: pointer;
	${media.greaterThan('mobile')`
    width: 150px;
  `};
`

const FormMessage = styled.div`
	font-size: 1rem;
	padding-top: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	color: ${(props) => (props.isError ? 'red' : props.theme.colors.white)};
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`

const SignUpForm = (props) => {
	const { CTATitle, FormLabel } = props
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [successMessage, setSuccessMessage] = React.useState('Thank you!')
	const [isError, setIsError] = React.useState(false)

	const [formData, setFormData] = React.useState({
		values: { email: '' },
		errors: { email: '' }
	})

	const handleSubmit = async () => {
		const errors = _map(formData.errors, (value) => value)

		if (errors.length > 0) return

		const data = {
			submittedAt: Date.now(),
			fields: [
				{
					name: 'email',
					value: formData.values.email
				}
			],
			context: {
				pageUri: 'www.redant.com/resources',
				pageName: 'Resources | Red Ant'
			}
		}

		const xhr = new XMLHttpRequest()

		xhr.open('POST', process.env.GATSBY_HUBSPOT_SIGNUP_FORM_API)
		xhr.setRequestHeader('Content-Type', 'application/json')

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4 && xhr.status === 200) {
				const { inlineMessage } = JSON.parse(xhr.responseText) || {}
				setIsSubmitted(true)
				setSuccessMessage(_replace(_replace(inlineMessage, '<p>', ''), '</p>', ''))
				// reset form data
				setFormData({
					values: { email: '' },
					errors: { email: '' }
				})
			} else if (xhr.readyState === 4 && xhr.status === 400) {
				// Returns a 400 error the submission is rejected.
				setIsSubmitted(false)
				setIsError(true)
			} else if (xhr.readyState === 4 && xhr.status === 403) {
				// Returns a 403 error if the portal isn't allowed to post submissions.
				setIsSubmitted(false)
				setIsError(true)
			} else if (xhr.readyState === 4 && xhr.status === 404) {
				//Returns a 404 error if the formGuid isn't found
				setIsSubmitted(false)
				setIsError(true)
			}
		}

		xhr.send(JSON.stringify(data))
	}

	const handleChange = (event) => {
		const name = _get(event, 'target.name')
		const value = _get(event, 'target.value')
		const newData = {
			...formData.values,
			[name]: value
		}
		setFormData({
			values: newData,
			errors: {
				...validate(newData)
			}
		})
		setIsSubmitted(false)
		setIsError(false)
	}

	const validate = (values) => {
		const errors = {}

		if (!values.email) {
			errors.email = 'Required'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address'
		}
		return errors
	}

	return (
		<StyledForm>
			<StyledLabel htmlFor="email">{FormLabel}</StyledLabel>
			<FieldWrapper key={'email'}>
				<StyledInputField
					id={'email'}
					name={'email'}
					type={'email'}
					value={formData.values.email}
					onfocusout={handleChange}
					onChange={handleChange}
				/>
				<ButtonWrapper>
					<SubmitButton type="button" onClick={handleSubmit}>
						{CTATitle}
					</SubmitButton>
				</ButtonWrapper>
			</FieldWrapper>

			{((isError || formData.errors.email) && !isSubmitted)
				? (
					<FormMessage isError>
						{isError ? `Something went wrong. Please try again later.` : formData.errors.email}
					</FormMessage>
				)
				: isSubmitted && (
					<FormMessage>{successMessage}</FormMessage>
				)}
		</StyledForm>
	)
}

export default SignUpForm
