import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import H2 from '../H2'
import Container from '../Container'
import Paper from '../Paper'
import vars from '../../styles/vars'
import utils from '../../utils'


const HeaderWrapper = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;
  max-width: ${vars.contentWidth.lg}rem;
  margin: auto;
`

const PaperWithTitle = (props) => {
  const {
    paper_body,
    title
  } = props
  return (
    <Container backgroundColor={'light'}>
      <HeaderWrapper>
        <H2 variant='bigHeading'>{title}</H2>
      </HeaderWrapper>
      <Paper>
        <RichText render={paper_body} htmlSerializer={utils.htmlSerializer} />
      </Paper>
    </Container>
  )
}

export default PaperWithTitle