import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import _map from 'lodash/map'
import media from '../../styles/media'

import H4 from '../H4'
import utils from '../../utils'

const ContactBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${media.greaterThan('mobile')`
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
  `}
`
const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  p {
    padding-bottom: 0;
  }
  h4 {
    padding-bottom: .5rem;
  }
`

const ContactBlock = (props) => {
  const { repeatableFields } = props
  return (
    <ContactBlockContainer>
      {_map(repeatableFields, (field, index) => {
        return (
          <ContactCard key={index}>
            <H4 variant='secondary'>{field.title}</H4>
            <RichText render={field.contact_details} htmlSerializer={utils.htmlSerializer} />
          </ContactCard>
        )
      })}
    </ContactBlockContainer>
  )
}

export default ContactBlock