import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { variant } from 'styled-system'
import theme from '../styles/theme'
import arrowIcon from '../images/Icon-RightChev.svg'
import optionsIcon from '../images/Icon-Options.png'
import checkIcon from '../images/Icon-Check.svg'
import checkIconInactive from '../images/Icon-Check-Inactive.svg'
import rgba from '../utils/rgba'

const ButtonPrimary = {
	minWidth: '12.625rem',
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '1.125rem',
	fontWeight: 'bold',
	textAlign: 'center',
	lineHeight: '1.375rem',
	padding: '1.188rem 2.647rem',
	cursor: 'pointer',
	color: theme.colors.white,
	background: theme.colors.dark,
	'&:hover': {
		background: rgba(theme.colors.dark, 0.8),
		cursor: 'pointer'
	}
}

const ButtonSecondary = {
	minWidth: '12.625rem',
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '1.125rem',
	lineHeight: '1.137rem',
	fontWeight: 'bold',
	padding: '1.313rem 1.580rem',
	color: theme.colors.black,
	backgroundColor: theme.colors.white,
	'&:hover': {
		color: rgba(theme.colors.black, 0.8),
		cursor: 'pointer'
	}
}
const ButtonHeroCta = {
	...ButtonSecondary,
	margin: '0 3rem',
	border: `0.1rem solid ${rgba(theme.colors.darkBackground, 0.2)} !important`
}

const PrimaryGreenButton = {
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '1.125rem',
	lineHeight: '1.137rem',
	fontWeight: 'bold',
	padding: '1rem 3rem',
	color: theme.colors.white,
	backgroundColor: theme.colors.green,
	'&:hover': {
		color: rgba(theme.colors.black, 0.8),
		cursor: 'pointer'
	}
}

const ButtonWithRoundedCorners = {
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
	minHeight: '1.75rem',
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '0.687rem',
	fontWeight: 'bold',
	textTransform: 'uppercase',
	borderRadius: '18rem',
	borderColor: 'transparent',
	padding: '0 .8rem',
	cursor: 'pointer',
	color: theme.colors.white,
	boxShadow: `0 5px 20px -10px ${rgba(theme.colors.light, 0.4)}`,
	backgroundColor: theme.colors.darkBackground,
	'&:hover': {
		backgroundColor: rgba(theme.colors.darkBackground, 0.8),
		cursor: 'pointer'
	}
}

const ButtonWithArrow = {
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '0.875rem',
	lineHeight: '1.5rem',
	fontWeight: 'bold',
	padding: '1.313rem 1.580rem',
	color: theme.colors.black,
	backgroundColor: 'transparent',
	'&:hover': {
		color: rgba(theme.colors.black, 0.8),
		cursor: 'pointer'
	}
}

const ButtonWithOptions = {
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '1.187rem',
	lineHeight: '1.5rem',
	fontWeight: 'bold',
	padding: '1.313rem 1.580rem',
	color: theme.colors.black,
	backgroundColor: 'transparent',
	'&:hover': {
		color: rgba(theme.colors.black, 0.8),
		cursor: 'pointer'
	}
}

const ButtonWithCheck = {
	fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
	fontSize: '1.06rem',
	lineHeight: 0.8,
	padding: '1.25rem',
	color: theme.colors.black,
	backgroundColor: theme.colors.light,
	'&:hover': {
		cursor: 'pointer'
	}
}

const ButtonVariant = styled.button(
	variant({
		variants: {
			primary: ButtonPrimary,
			secondary: ButtonSecondary,
			heroButton: ButtonHeroCta,
			roundCornersDark: ButtonWithRoundedCorners,
			roundCornersLight: {
				...ButtonWithRoundedCorners,
				color: theme.colors.darkBackground,
				background: theme.colors.light,
				'&:hover': {
					color: theme.colors.white,
					background: rgba(theme.colors.darkBackground, 0.8),
					cursor: 'pointer'
				}
			},
			roundCornersRed: {
				...ButtonWithRoundedCorners,
				color: theme.colors.white,
				background: theme.colors.secondary,
				'&:hover': {
					background: rgba('#F73C49', 0.8),
					cursor: 'pointer'
				}
			},
			checkButton: ButtonWithCheck,
			primaryGreen: PrimaryGreenButton
			}
		})
		)
		
const ButtonLinkVariant = styled(Link)(
	variant({
		variants: {
			primary: ButtonPrimary,
			secondary: ButtonSecondary,
			heroButton: ButtonHeroCta,
			roundCornersDark: ButtonWithRoundedCorners,
			roundCornersLight: {
				...ButtonWithRoundedCorners,
				color: theme.colors.darkBackground,
				background: theme.colors.light,
				'&:hover': {
					color: theme.colors.white,
					background: rgba(theme.colors.darkBackground, 0.8),
					cursor: 'pointer'
				}
			},
			roundCornersRed: {
				...ButtonWithRoundedCorners,
				color: theme.colors.white,
				background: theme.colors.secondary,
				'&:hover': {
					background: rgba('#F73C49', 0.8),
					cursor: 'pointer'
				}
			},
			arrowButton: ButtonWithArrow,
			optionsButton: ButtonWithOptions
		}
	})
)

const ButtonExternalLinkVariant = styled('a')({ ...ButtonPrimary })


const ButtonLinkArrowVariantWrapper = styled(ButtonLinkVariant)`
	display: flex;
	align-items: center;
	> img {
		width: .5rem;
		margin-left: .6rem;
	}
`

const ButtonLinkArrowVariant = ({ children, to, ...rest }) => (
	<ButtonLinkArrowVariantWrapper to={to} variant="arrowButton">
		{children}
		<img src={arrowIcon} alt="arrow" />
	</ButtonLinkArrowVariantWrapper>
)

const ButtonLinkOptionsVariantWrapper = styled(ButtonLinkVariant)`
	display: flex;
	align-items: center;
	text-decoration: underline;

	> img {
		width: 1.06rem;
			margin-right: .6rem;
	}
`

const ButtonLinkOptionsVariant = ({ children, to, ...rest }) => (
	<ButtonLinkOptionsVariantWrapper to={to} variant="optionsButton">
		<img src={optionsIcon} alt="options icon" />
		{children}
	</ButtonLinkOptionsVariantWrapper>
)

const ButtonWithCheckVariantWrapper = styled(ButtonVariant)`
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;

	> img {
		width: 1.06rem;
		margin-left: .6rem;
	}
`

const ButtonWithCheckVariant = ({ children, onClick, checked, ...rest }) => (
	<ButtonWithCheckVariantWrapper variant="checkButton" onClick={onClick}>
		{children}
		<img src={checked ? checkIcon : checkIconInactive} alt="check icon" />
	</ButtonWithCheckVariantWrapper>
)

const Button = styled((props) => {
	return (
		{
			optionsButton: <ButtonLinkOptionsVariant to={props.href} {...props} />,
			arrowButton: <ButtonLinkArrowVariant to={props.href} {...props} />,
			linkButton: <ButtonLinkVariant to={props.href} {...props} />,
			externalLinkButton: <ButtonExternalLinkVariant to={props.href} target={'_blank'} {...props} />,
			defaultButton: <ButtonVariant {...props} />,
			checkButton: <ButtonWithCheckVariant {...props} />
		}[props.variant] ||
		(props.href ? <ButtonLinkVariant to={props.href} {...props} /> : <ButtonVariant {...props} />)
	)
})`
  border: none;
`

export default Button
