import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import styledMedia from 'styled-media-query'
import _get from 'lodash/get'
import Image from '../Image'
import styled from 'styled-components'

import Container from '../Container'
import RequestDemoForm from '../Forms/RequestDemoForm'
import H2 from '../H2'
import H3 from '../H3'

import media from '../../styles/media'

const Wrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin: 2rem auto;
max-width: 1200px;
`

const TitleWrapper = styled.div`
	text-align: center;
	max-width: 950px;
	margin: 0 auto;
	z-index: 0;
	${media.greaterThan('mobile')`
	padding: 0 1.375rem;
	`};
`

const SubtitleWrapper = styled.div`
	padding: 0 4.4rem;
	${media.lessThan('tablet')`
    padding: 0;
  `};
`

const DefaultImageWrapper = styled.div`
z-index: -1;
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	${styledMedia.lessThan('1465px')`
		top: 60%;
	`};
	${styledMedia.lessThan('1123px')`
    	display: none;
  	`};
`
const DefaultLeftImageWrapper = styled(DefaultImageWrapper)` 
  left: 0;
  text-align: left;
  > img { width: 446px; }
  ${styledMedia.lessThan('1465px')`
    > img { width: 223px; }
  `};
`
const DefaultRightImageWrapper = styled(DefaultImageWrapper)`
  right: 0;
  text-align: right;
  > img { width: 336px; }
  ${styledMedia.lessThan('1465px')`
    > img { width: 168px; }
  `};
`

const ImageWrapper = styled.div`
	z-index: -1;
	height: 100%;
	${styledMedia.lessThan('1123px')`
    	display: none;
  	`};
`
const LeftImageWrapper = styled(ImageWrapper)` 
`
const RightImageWrapper = styled(ImageWrapper)`
`

const FormWrapper = styled.div`
	position: relative;
	padding: 0 1.25rem;
`

const ContactFormPharmaOS = ({ type, title, sub_title, cta_text, contact_form_type, pageTitle, typename, image_left, image_right }) => {
	// Have default set to reatailos to ensure backward compatibility
	const contactFormType = contact_form_type || 'retailos'
	const leftImage = image_left
	const rightImage = image_right
	const imagesQuery = graphql`
		query {
			leftImageDefault: file(relativePath: { eq: "contact-form-bg-left.png" }) {
				childImageSharp {
					fixed {
						src
					}
				}
			}
			rightImageDefault: file(relativePath: { eq: "contact-form-bg-right.png" }) {
				childImageSharp {
					fixed {
						src
					}
				}
			}
		}
	`

	return (
		<StaticQuery
			query={`${imagesQuery}`}
			render={withPreview((data) => {
				const leftImageDefault = _get(data, 'leftImageDefault.childImageSharp.fixed')
				const rightImageDefault = _get(data, 'rightImageDefault.childImageSharp.fixed')
				return (
					<Container
						type={type}
						noPadding={typename !== 'PRISMIC_Product_pageBodyContact_form'}
						fullSectionWidth
						fullWidth
					>
						<Wrapper>
							{
								leftImage
									? (
										<LeftImageWrapper>
											<Image {...leftImage} />
										</LeftImageWrapper>
									)
									: (
										<DefaultLeftImageWrapper>
											<Image url={leftImageDefault.src} alt={''} />
										</DefaultLeftImageWrapper>
									)
							}
							<div>
								<TitleWrapper>
									<H2 variant="heading" textAlign="center">
										{title}
									</H2>
									<SubtitleWrapper>
										<H3 variant="subtitle" textAlign="center">
											{sub_title}
										</H3>
									</SubtitleWrapper>
								</TitleWrapper>
								<FormWrapper>
									<RequestDemoForm pageTitle={pageTitle} CTAtext={cta_text} contactFormType={contactFormType} />
								</FormWrapper>
							</div>
							{
								rightImage
									? (
										<RightImageWrapper>
											<Image {...rightImage} />
										</RightImageWrapper>
									)
									: (
										<DefaultRightImageWrapper>
											<Image url={rightImageDefault.src} alt={''} />
										</DefaultRightImageWrapper>
									)
							}
						</Wrapper>
					</Container>
				)
			}, imagesQuery)}
		/>
	)
}

export default ContactFormPharmaOS
