import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import H2 from './../H2'
import H3 from './../H3'
import Container from './../Container'
import Button from './../Button'

import media from '../../styles/media'
import utils from '../../utils'

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	background-color: red;
	background: url(${(props) => props.image_url}) no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`

const Box = styled.div`
	width: 100%;
	max-width: 52rem;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 3.375rem auto;
	padding: 3.375rem 6.575rem;
	background-color: ${(props) => props.theme.colors.white};
	box-shadow: 0 30px 30px 0 rgba(58, 58, 74, 0.1);
	${media.lessThan('tablet')`
    padding: 1.375rem 1.25rem;
  `};
`
const ContentWrapper = styled.div`
	max-width: 29.625rem;
	padding-bottom: 3.147rem;
	${media.lessThan('tablet')`
    max-width: initial;
    padding-bottom: .6rem;
  `};
`

const ButtonWrapper = styled.div`display: flex;`
const HashtagWrapper = styled.div`
	display: flex;
	padding-top: 1rem;
`

const BackgroundImageWithText = ({
	type,
	title,
	sub_title,
	background_image,
	content,
	social_media_hashtags,
	social_media_link
}) => {
	return (
		<Container fullWidth fullSectionWidth noPadding>
			<Wrapper image_url={background_image.url}>
				<Box>
					<H2 variant={'bigHeading'}>{title}</H2>
					<H3 variant={'primary'}>{sub_title}</H3>
					<ContentWrapper>
						<RichText render={content} htmlSerializer={utils.htmlSerializer} />
					</ContentWrapper>
					{social_media_link && (
						<ButtonWrapper>
							<Button
								href={utils.linkResolver(social_media_link)}
								variant="externalLinkButton"
							>{`Follow us`}</Button>
						</ButtonWrapper>
					)}
					{social_media_hashtags && (
						<HashtagWrapper>
							<p>{social_media_hashtags}</p>
						</HashtagWrapper>
					)}
				</Box>
			</Wrapper>
		</Container>
	)
}

export default BackgroundImageWithText
