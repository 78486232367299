import styled from "styled-components"
import { variant } from "styled-system"

import theme from "../styles/theme"

const H4Variant = styled("h4")(
  variant({
    variants: {
      primary: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: "1.0625rem",
        lineHeight: 1.17
      },
      secondary: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: "1.3127rem",
        lineHeight: 1.19,
        paddingBottom: '10px'
      },
      richTextRoboto: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: '1.075rem',
        lineHeight: 1.17
      },
      richTextFutura: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: "1.0625rem",
        lineHeight: 1.17
      },
    },
  })
)

const H4 = styled(H4Variant)`
  color: ${props =>
    props.color === "light" ? theme.colors.white : theme.colors.dark};
  text-align: ${props => props.textAlign};
`

export default H4
