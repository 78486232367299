import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import _map from 'lodash/map'

import Image from '../image'
import H4 from '../H4'
import utils from '../../utils'
import media from '../../styles/media'

import ContactUsForm from '../Forms/ContactUsForm'


const LocationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.greaterThan('mobile')`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
`}
  ${media.greaterThan('desktop')`
  flex-direction: column;
  width: 40%;
`}
`
const TileWrapper = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: ${props => props.index === 0 ? `1px 33px 21px -5px rgba(0,0,0,0.08)` : null};
  -moz-box-shadow:  ${props => props.index === 0 ? `1px 33px 21px -5px rgba(0,0,0,0.08)` : null};
  box-shadow: ${props => props.index === 0 ? `1px 33px 21px -5px rgba(0,0,0,0.08)` : null};
  ${media.greaterThan('mobile')`
  align-content: center;
  flex-direction: row;
  width: 50%;
`}
  ${media.greaterThan('desktop')`
  align-content: center;
  flex-direction: row;
  width: 100%;
`}
`
const ImageWrapper = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 120px;
  }
  ${media.greaterThan('mobile')`
  img {
    width: 100px;
  }
    width: 30%;
  `}
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 1rem;
  p {
    padding-bottom: 0;
  }
  h4 {
    padding-bottom: .5rem;
  }
${media.greaterThan('mobile')`
  align-items: flex-start;
  text-align: left;
  padding-left: 2rem;
  width: 70%;
  `}
${media.greaterThan('desktop')`
  padding-left: 1rem;
  `}
`
const InnerTextWrapper = styled.div`
  width: 80%;
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  ${media.greaterThan('mobile')`
  flex-direction: column-reverse;
  `}
  ${media.greaterThan('desktop')`
  flex-direction: row;
    padding-top: 2rem;
  `}
`

const Locations = ({ pageTitle, repeatableFields }) => {
  return (
    <SectionWrapper>
      <ContactUsForm pageTitle={pageTitle} />
      <LocationsContainer>
        {_map(repeatableFields, (field, index) => {
          const { address, location_image } = field
          return (
            <TileWrapper key={index}>
              <ImageWrapper>
                <Image {...location_image} />
              </ImageWrapper>
              <TextWrapper>
                <H4 variant='secondary'>{field.location_name}</H4>
                <InnerTextWrapper>
                  <RichText render={address} htmlSerializer={utils.htmlSerializer} />
                </InnerTextWrapper>
              </TextWrapper>
            </TileWrapper>

          )
        })}
      </LocationsContainer>
    </SectionWrapper>

  )
}

Locations.propTypes = {
  pageTitle: PropTypes.string,
  repeatableFields: PropTypes.array
}


export default Locations
