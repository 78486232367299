import React from 'react'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import fetch from 'node-fetch'
import _get from 'lodash/get'
import _map from 'lodash/map'

import Button from './../Button'

import media from './../../styles/media'
import theme from '../../styles/theme'
import dropDownArrow from '../../images/Icon-SelectArrow.svg'

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	max-width: 750px;
	margin: 3rem auto;
`

const FieldWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
	text-align: right;

	${media.lessThan('tablet')`
		text-align: left;
		flex-direction: column;
	`};
`

const StyledLabel = styled.label`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.187rem;
	line-height: 2.6;
	vertical-align: top;
`

const StyledErrorMessage = styled.div`	
	display: flex;
	flex: 1 1 100%;
	height: 25px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	color: red;
	
	${media.greaterThan('tablet')`
		position: absolute;
		bottom: 0;
		right: 0;
	`};
`

const StyledInputField = styled.input`
	width: 70%;
	height: 55px;
	padding: 25px;
	margin-left: 2.5rem;
	margin-bottom: 1.875rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${(props) => props.theme.colors.darkSecondary};
	border: 0.187rem solid ${(props) => props.theme.colors.light};

	${media.lessThan('tablet')`
		width: 100%;
		margin-left: 0;
		margin-bottom: 1rem;
	`};
`

const StyledTextareaField = styled.textarea`
	width: 70%;
	padding: 25px;
	margin-left: 2.5rem;
	margin-bottom: 1.875rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${(props) => props.theme.colors.darkSecondary};
	border: 0.187rem solid ${(props) => props.theme.colors.light};
	resize: none;
	${media.lessThan('tablet')`
    width: 100%;
    margin-left: 0;
    margin-bottom: 1rem;
  `};
`

const StyledSelectField = styled.select`
	width: 70%;
	height: 55px;
	padding: 0 25px;
	margin-left: 1.25rem;
	margin-bottom: 1.875rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	background: ${theme.colors.white};
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	color: ${(props) => props.theme.colors.darkSecondary};
	border: 0.187rem solid ${(props) => props.theme.colors.light};
	background-image: url(${dropDownArrow});
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;

	${media.lessThan('tablet')`
    width: 100%;
    margin:0;
  `};
`

const FormMessage = styled.div`
	padding-top: 2rem;
	text-align: right;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	color: ${(props) => (props.isError ? 'red' : props.theme.colors.darkSecondary)};
`

const ButtonWrapper = styled.div`
	display: flex;
	margin-top: 1rem;
	justify-content: flex-end;
	${media.greaterThan('tablet')`
		margin-top: 0.625rem;
	`};
	${media.greaterThan('desktop')`
		margin-top: 0.625rem;
		
	`};
`

const ButtonWrapperInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
	> button { 
		width: 100%;
		margin-top: 1rem;
		padding: 1.188rem 1.647rem;
	}
	${media.greaterThan('tablet')`
		width: 70%;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		> button { 
			width: initial;
			margin: 0;
		 }
	`}
	${media.greaterThan('desktop')`
		width: 70%;
		flex-direction: row;	
		justify-content: space-between;
		align-items: flex-start;
		> button { 
			width: initial;
			margin: 0;
		}
	`}
`

const ErrorMessageWrapper = styled.div`
	display:flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
	> div {
		color:red;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
		height: 3.75rem;
		${media.greaterThan('tablet')`
			width: 70%;
		`}
		${media.greaterThan('desktop')`
			width: 70%;
		`}
	}
`

const RequestDemoForm = ({ pageTitle, CTAtext, contactFormType }) => {
	const MAX_NUM_CHAR_MESSAGE = 6000

	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [isError, setIsError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')
	const [successMessage, setSuccessMessage] = React.useState('')
	const [formData, setFormData] = React.useState({
		values: {
			name: '',
			companyName: '',
			email: '',
			storesNumber: '',
			message: ''
		},
		errors: {
			name: '',
			companyName: '',
			email: '',
			storesNumber: '',
			message: ''
		}
	})
	const [isExpired, setIsExpired] = React.useState(true)

	const recaptchaRef = React.createRef()

	const executeReCaptcha = async () => {
		return new Promise(async (resolve, reject) => {
			let attempt = 0
			let token = recaptchaRef.current.getValue()

			if (token) {
				recaptchaRef.current.reset()
			}

			recaptchaRef.current.execute()

			const tokenValueCheck = setInterval(() => {
				const value = recaptchaRef.current.getValue()
				attempt++
				if (value) {
					clearInterval(tokenValueCheck)
					resolve(value)
				}
				if (attempt > 12) {
					clearInterval(tokenValueCheck)
					reject('no token')
				}
			}, 500)
		})
	}

	const handleCaptchaChange = (token) => setIsExpired(!!token)

	const handleSubmit = async () => {
		const recaptchaToken = recaptchaRef.current.getValue()

		handleValidation(formData.values)

		const errors = _map(formData.errors, (value) => value)

		if (!recaptchaToken || Object.keys(errors).length) return

		const reqConfig = {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'omit',
			redirect: 'follow',
			referrer: 'client',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				...formData.values,
				pageTitle,
				"g-recaptcha-response": recaptchaToken
			})
		}

		await fetch(process.env.GATSBY_AWS_CONTACT_FORM_API, reqConfig)
			.then((response) => response.json())
			.then((result) => {
				if (!result.success) { throw new Error('Something went wrong. Please try again later.') }
				setIsSubmitted(true)
				// reset form data after submit
				setFormData({
					values: {
						name: '',
						companyName: '',
						email: '',
						storesNumber: '',
						message: ''
					},
					errors: {
						name: '',
						companyName: '',
						email: '',
						storesNumber: '',
						message: ''
					}
				})
				setSuccessMessage(result.message)
			})
			.catch((error) => {
				setIsSubmitted(false)
				setIsError(true)
				setErrorMessage(error.message ? error.message : error)
			})
	}

	const handleValidation = (data) => {
		setFormData({
			values: data,
			errors: {
				...validate(data)
			}
		})
	}

	const handleChange = (event) => {
		const name = _get(event, 'target.name')
		const value = _get(event, 'target.value')
		const newData = {
			...formData.values,
			[name]: value
		}
		handleValidation(newData)
	}

	const validate = (values) => {
		const errors = {}

		if (!values.name || values.name === '') {
			errors.name = 'Required'
		}

		if (!values.companyName || values.companyName === '') {
			errors.companyName = 'Required'
		}

		if (!values.email || values.email === '') {
			errors.email = 'Required'
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address'
		}

		if (values.message.length >= MAX_NUM_CHAR_MESSAGE) {
			errors.message = `Message is too long (Maximum is ${MAX_NUM_CHAR_MESSAGE} characters)`
		}

		return errors
	}

	return (
		<StyledForm>
			<FieldWrapper key={'name'}>
				<StyledLabel htmlFor="name">Name</StyledLabel>
				<StyledInputField
					id={'name'}
					name={'name'}
					type={'text'}
					value={formData.values.name}
					onfocusout={handleChange}
					onChange={handleChange}
				/>
				{formData.errors.name && <StyledErrorMessage>{formData.errors.name}</StyledErrorMessage>}
			</FieldWrapper>

			<FieldWrapper key={'companyName'}>
				<StyledLabel htmlFor="companyName">Company name</StyledLabel>
				<StyledInputField
					id={'companyName'}
					name={'companyName'}
					type={'text'}
					value={formData.values.companyName}
					onfocusout={handleChange}
					onChange={handleChange}
				/>
				{formData.errors.companyName && <StyledErrorMessage>{formData.errors.companyName}</StyledErrorMessage>}
			</FieldWrapper>

			<FieldWrapper key={'email'}>
				<StyledLabel htmlFor="email">Email</StyledLabel>
				<StyledInputField
					id={'email'}
					name={'email'}
					type={'email'}
					value={formData.values.email}
					onfocusout={handleChange}
					onChange={handleChange}
				/>
				{formData.errors.email && <StyledErrorMessage>{formData.errors.email}</StyledErrorMessage>}
			</FieldWrapper>

			{contactFormType==='retailos' && <FieldWrapper key={'storesNumber'}>
				<StyledLabel htmlFor="storesNumber">Number of stores</StyledLabel>
				<StyledSelectField
					id="storesNumber"
					name="storesNumber"
					component="select"
					placeholder="Please select"
					value={formData.values.storesNumber}
					onfocusout={handleChange}
					onChange={handleChange}
				>
					<option value="">Please select</option>
					<option value="Less than 50">Less than 50</option>
					<option value="50-200">50-200</option>
					<option value="200-500">200-500</option>
					<option value="More than 500">More than 500</option>
				</StyledSelectField>
			</FieldWrapper>}

			<FieldWrapper key={'message'}>
				<StyledLabel htmlFor="message">Message</StyledLabel>
				<StyledTextareaField
					id={'message'}
					name={'message'}
					type={'textarea'}
					rows={'5'}
					maxLength={MAX_NUM_CHAR_MESSAGE}
					value={formData.values.message}
					onfocusout={handleChange}
					onChange={handleChange}
				/>
				{formData.errors.message && <StyledErrorMessage>{formData.errors.message}</StyledErrorMessage>}
			</FieldWrapper>

			<ButtonWrapper>
				<ButtonWrapperInner>
					<ReCAPTCHA
						ref={recaptchaRef}
						theme="light"
						badge="inline"
						render="explicit"
						size="invisible"
						sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
						onChange={handleCaptchaChange}
					/>
					<Button variant="primary" type="button" onClick={async () => {
						try {
							const token = await executeReCaptcha()
							handleSubmit(token)
						} catch (e) {
							console.log({ e })
							setIsError(true)
							setErrorMessage('Something went wrong. Please try again later.')
						}
					}}>
						{CTAtext || 'Send'}
					</Button>
				</ButtonWrapperInner>
			</ButtonWrapper>

			{(formData.errors.name || formData.errors.companyName || formData.errors.email) &&
				<ErrorMessageWrapper>
					<div>Missing fields required</div>
				</ErrorMessageWrapper>}

			{isSubmitted && <FormMessage>{successMessage}</FormMessage>}
			{isError && <FormMessage isError>{errorMessage}</FormMessage>}
		</StyledForm>
	)
}

export default RequestDemoForm
