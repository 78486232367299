import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import _map from 'lodash/map'

import Image from '../image'
import media from '../../styles/media'
import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'
import P from '../P'
import utils from '../../utils'

const TitleWrapper = styled.div``

const IconsContainer = styled.div`
	display: flex;
	flex-direction: column;
	${media.greaterThan('tablet')`
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 2rem;
  `};
`
const IconParent = styled.div`
	flex-direction: column;
	padding: 1rem;
	align-items: center;
	flex: 1 0 30%;
	text-align: center;
`
const IconTextWrapper = styled.div`
	padding-top: 1rem;
	text-align: center;
`

const ImageWrapper = styled.div`
	padding: 1rem;
	img {
		width: 70%;
		height: 100%;
	}
`
const InnerWrapper = styled.div`
	${media.greaterThan('tablet')`
  padding-left: 5rem;
  padding-right: 5rem;
`};
`

const FullPageIconPanel = ({ type, main_title, subtitle, repeatableFields }) => {
	return (
		<Container type={type}>
			<InnerWrapper>
				<TitleWrapper>
					<H2 variant="heading" textAlign="center">
						{main_title}
					</H2>
					<P variant="subtitle" textAlign="center">
						{subtitle}
					</P>
				</TitleWrapper>
				<IconsContainer>
					{_map(repeatableFields, ({ icon_image, icon_subtitle, icon_title, icon_link }) => {
						return (
							<IconParent key={icon_title}>
								<Link to={utils.linkResolver(icon_link)}>
									<ImageWrapper>
										<Image {...icon_image} />
									</ImageWrapper>
									<IconTextWrapper>
										<H3 variant="smallHeading" textAlign="center">
											{icon_title}
										</H3>
										<P variant="primary">{icon_subtitle}</P>
									</IconTextWrapper>
								</Link>
							</IconParent>
						)
					})}
				</IconsContainer>
			</InnerWrapper>
		</Container>
	)
}

export default FullPageIconPanel
