import React from 'react'
import styled from 'styled-components'
import _map from "lodash/map"

import H2 from '../H2'
import H3 from '../H3'
import H4 from '../H4'
import P from '../P'
import theme from '../../styles/theme'
import media from '../../styles/media'
import Image from '../../components/image'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import vars from '../../styles/vars'


const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.greaterThan('tablet')`
    flex-direction: row;
    padding-bottom: 1.5rem;
  `}
`
const LeftContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.greaterThan('tablet')`
    width: 20%
  `}
`
const RightContainer = styled.div`
  width: 100%;
  text-align: center;
  ${media.greaterThan('tablet')`
    width: 80%;
    padding: 0 2rem;
    text-align: left;
  `}
`
const HeaderWrapper = styled.div`
  padding: 1.5rem 1rem;
  text-align: center;
  max-width: ${vars.contentWidth.lg}rem;
  margin: auto;
  ${media.greaterThan('tablet')``}
`
const ImageWrapper = styled.div`
  text-align: center;
  img {
    width: 50%;
    ${media.greaterThan('tablet')`
      width: 100%
    `}
  }
`

const ColumnOfBanners = (props) => {
  const {
    type,
    pageTitle,
    typename,
    no_items_left_message,
    subtitle,
    title,
    repeatableFields,
  } = props

  return (
    <Container backgroundColor={'light'}>
      <HeaderWrapper>
        <H2 variant='bigHeading'>{title}</H2>
        <H3 variant='primary'>{subtitle}</H3>
      </HeaderWrapper>
      <Paper>
        {_map(repeatableFields, (field) => {
          const {
            section_body,
            section_button,
            section_button_link,
            section_title,
            image
          } = field
          return (
            <SectionWrapper>
              <LeftContainer>
                <ImageWrapper>
                  <Image {...image} />
                </ImageWrapper>
              </LeftContainer>
              <RightContainer>
                <H4 variant='secondary'>{section_title}</H4>
                <P variant='primary'>{section_body}</P>
              </RightContainer>
            </SectionWrapper>
          )
        })}
      </Paper>
    </Container>
  )
}

export default ColumnOfBanners