import _startsWith from 'lodash/startsWith'

// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

/**
 * @description takes a color hex and converts to rgba using opacity value
 * @param {*} color
 * @param {*} opacity
 * @returns {string}
 */
const rgba = (color, opacity) => {
  const isHex = _startsWith(color, '#')
  if (isHex){
    const rgb = hexToRgb(color)
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`
  }
  return color
}

export default rgba