import React from 'react'
import styled from "styled-components"

import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'

const TitleWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 56.25rem;
`

const TextBanner = ({
  title,
  subtitle,
  background_color
}) => {
  return (
    <Container
      noBottomPadding
      backgroundColor={background_color}>
      <TitleWrapper>
        <H2 variant={'bigHeading'}>{title}</H2>
        <H3 variant={'subtitle'}>{subtitle}</H3>
      </TitleWrapper>
    </Container>
  )
}

export default TextBanner