import React, { useCallback, useState } from 'react'
import styled from "styled-components"
import media from "../../styles/media"
import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'
import play_button from "./assets/play_button.png"
import Image from '../image'
import { AppContext } from '../../AppProvider'

const TitleWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 80rem;
  display: flex;
	flex-direction: row;
  flex-direction: column;
  justify-content: center;
`

const H2Style = styled.div`
  font-size: 48px;
`

const H3StyleBold = styled.div`
  font-size: 20px;
  font-weight: bold;
	${media.lessThan('tablet')`
    line-height: 1.5;
  `};
`

const DescWrapper = styled.div`
	display: flex;
	flex-direction: row;
	${media.lessThan('tablet')`
    flex-direction: column;
    justify-content: center;
  `};
`

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-bottom: 20px;
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const H3Style = styled.div`
  font-size: 20px;
  text-align: justify;
  padding-left: 20px;
  padding-right: 20px;
  ${media.lessThan('tablet')`
    text-align: center;
  `};
`

const StartButton = styled.button`
  z-index: 9;
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  width: 198px;
  cursor: pointer;
  > img {
    width: 100%;
  }
  
  ${media.greaterThan('tablet')`
    position: absolute;
    top: -18px;
    left: 95px;
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const VideoContainer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: 0px;
  bottom: 80px;
  left: unset;
  transform: none;
  z-index: 1;
  > iframe {
    z-index: 2;
    top:0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  > img {
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  ${media.greaterThan("tablet")`
    width: 547px;
    height: 306px;
  `}
`

const PlayButton = styled.button`
  z-index: 4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) }
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 123px;
  > img {
    width: 100%;
  }
  ${media.greaterThan('tablet')`
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const ParaDesc = styled.div`
  line-height: 2;
  ${media.lessThan("tablet")`
    line-height: 1.5;
  `}
`

const LiStyle = styled.div`
  position: relative;
  left: 10px;
  right: 10px;
  > li > span {
    position: relative;
    left: 15px;
  }
`

const LongTextBanner = ({
  title,
  subtitle,
  background_color,
  description,
  vimeo_video_id,
  video_thumbnail
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayVideo = useCallback((e) => {
    e.stopPropagation()
    setIsPlaying(true)
  })

  return (
    <Container
      backgroundColor={background_color}>
      <TitleWrapper>
        <H2 variant={'bigHeading'}><H2Style>{title}</H2Style></H2>
        <H3 variant={'subtitle'}><H3StyleBold>{subtitle}</H3StyleBold></H3>
        <DescWrapper>
          {vimeo_video_id && 
          <VideoWrapper>
            <VideoContainer>
              {((vimeo_video_id && isPlaying) || !video_thumbnail) && <iframe src={`https://player.vimeo.com/video/${vimeo_video_id}${video_thumbnail ? '&autoplay=1' : ''}`} width="604" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>}
              {video_thumbnail && <Image {...video_thumbnail} alt={''}/>}
              {(!isPlaying && video_thumbnail) && <PlayButton onClick={handlePlayVideo}>
                {play_button && <img src={play_button} alt={'Play'}/>}
              </PlayButton>}
            </VideoContainer>
          </VideoWrapper>
          }
          <TextWrapper><H3 variant={'description'}><H3Style>{getDescription(description)}</H3Style></H3></TextWrapper>
        </DescWrapper>
      </TitleWrapper>
    </Container>
  )
}

function getDescription(description) {
  if (description.indexOf("•") == -1) {
    return <ParaDesc>{description}</ParaDesc>
  } else {
    let points = description.split("•") 
    let result = points.map((point, index) => {
      if (index == 0) {
        return ""
      } else {
        return (<LiStyle><li style={{paddingBottom: '10px', lineHeight: 1.5, listStyleType: 'disc'}}><span>{point}</span></li></LiStyle>)
      }
    })
    return (<ul style={{textAlign: "left"}}>{result}</ul>)
  }
}

export default LongTextBanner