import React, { useState } from 'react'

export const AppContext = React.createContext()

const Provider = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState({ open: false })
  const [modal, setModal] = useState({
    open: false,
    type: 'default',
    header: null,
    main: null,
    footer: null,
    modalProps: {}
  });

  const appState = {
    mobileMenu,
    toggleMenu: () => setMobileMenu({ open: !mobileMenu.open }),
    modal,
    openModal: (modal) => setModal({ open: true, ...modal }),
    closeModal: () => setModal({
      open: false,
      type: 'default',
      header: null,
      main: null,
      footer: null,
      modalProps: {}
    })
  }

  return (
    <AppContext.Provider value={appState}>
      {children}
    </AppContext.Provider>
  )
};

export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
);