import React from 'react'
import styled from 'styled-components'
import { Elements } from 'prismic-reactjs'
import _get from 'lodash/get'

import P from '../components/P'
import H1 from '../components/H1'
import H2 from '../components/H2'
import H3 from '../components/H3'
import H4 from '../components/H4'
import Image from '../components/Image'
import ListItem from '../components/ListItem'
import CustomLink from '../components/CustomLink'
import linkResolver from './linkResolver'

const ImageWrapper = styled.div`
	> img {
		max-width: 100%;
	}
	padding-bottom: .4rem;
`

// -- HTML Serializer
const htmlSerializer = (type, element, content, children, key) => {
	const label = _get(element, 'spans.0.data.label')
	switch (type) {
		case Elements.heading1:
			return (
				<H1 key={key} variant={label === 'FuturaBTBold' ? 'richTextFutura' : label === 'Roboto' ? 'richTextRoboto' : 'primary'}>
					{children}
				</H1>
			)
		case Elements.heading2:
			return (
				<H2 key={key} variant={label === 'FuturaBTBold' ? 'richTextFutura' : label === 'Roboto' ? 'richTextRoboto' : 'primary'}>
					{children}
				</H2>
			)
		case Elements.heading3:
			return (
				<H3 key={key} variant={label === 'FuturaBTBold' ? 'richTextFutura' : label === 'Roboto' ? 'richTextRoboto' : 'primary'}>
					{children}
				</H3>
			)
		case Elements.heading4:
			return (
				<H4 key={key} variant={label === 'FuturaBTBold' ? 'richTextFutura' : label === 'Roboto' ? 'richTextRoboto' : 'secondary'}>
					{children}
				</H4>
			)
		case Elements.paragraph:
			return (
				<P key={key} variant="richText" color={label === 'white-text' ? 'light' : 'dark'}>
					{children}
				</P>
			)
		case Elements.list:
			return <ListItem key={key}>{children}</ListItem>
		case Elements.image:
			return (
				<ImageWrapper key={key}>
					<Image {...element} />
				</ImageWrapper>
			)
		case Elements.hyperlink:
			const data = _get(element, 'data')
			const uid = _get(data, 'uid')
			const customType = _get(data, 'type')
			const linkType = _get(data, 'link_type')
			return linkType === 'Web' ? (
				<CustomLink key={key} variant="primary" to={linkResolver({ url: _get(data, 'url') })} external>
					{children}
				</CustomLink>
			) : (
					<CustomLink key={key} variant="primary" to={linkResolver({ uid, type: customType })}>
						{children}
					</CustomLink>
				)
		default:
			return null
	}
}

export default htmlSerializer
