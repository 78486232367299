import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import styledMedia from 'styled-media-query'
import _get from 'lodash/get'
import Image from '../Image'
import styled from 'styled-components'

import Container from '../Container'
import Form from '../Forms/DownloadWhitepaperForm'
import H2 from '../H2'
import H3 from '../H3'

import media from './../../styles/media'

const Wrapper = styled.div`
	position: relative;
	${media.lessThan('tablet')`
        padding: 0 1.25rem;
    `};
`

const TitleWrapper = styled.div`
	text-align: center;
	padding: 0 1.375rem;
	max-width: 950px;
	margin: 0 auto;
	z-index: 0;
`

const SubtitleWrapper = styled.div`padding: 0 4.4rem;`

const ImageWrapper = styled.div`
	z-index: -1;
	width: auto;
	height: auto;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	${styledMedia.lessThan('1465px')`
        top: 60%;
    `};
	${styledMedia.lessThan('1123px')`
        display: none;
    `};
`
const LeftImageWrapper = styled(ImageWrapper)` 
  left: 0;
  text-align: left;
  > img { width: 446px; }
  ${styledMedia.lessThan('1465px')`
    > img { width: 223px; }
  `}
`
const RightImageWrapper = styled(ImageWrapper)`
  right: 0;
  text-align: right;
  > img { width: 336px; }
  ${styledMedia.lessThan('1465px')`
    > img { width: 168px; }
  `}
`

const FormWrapper = styled.div`position: relative;`

const DownloadWhitepaperForm = ({ type, title, subtitle, whitepaper_document, hubspot_form_id }) => {
	const imagesQuery = graphql`
		query {
			leftImage: file(relativePath: { eq: "contact-form-bg-left.png" }) {
				childImageSharp {
					fixed {
						src
					}
				}
			}
			rightImage: file(relativePath: { eq: "contact-form-bg-right.png" }) {
				childImageSharp {
					fixed {
						src
					}
				}
			}
		}
	`

	return (
		<StaticQuery
			query={`${imagesQuery}`}
			render={withPreview((data) => {
				const leftImage = _get(data, 'leftImage.childImageSharp.fixed')
				const rightImage = _get(data, 'rightImage.childImageSharp.fixed')
				return (
					<Container type={type} noPadding fullSectionWidth fullWidth>
						<Wrapper>
							<LeftImageWrapper>
								<Image url={leftImage.src} alt={'left-image'} />
							</LeftImageWrapper>
							<TitleWrapper>
								<H2 variant="heading" textAlign="center">
									{title}
								</H2>
								<SubtitleWrapper>
									<H3 variant="subtitle" textAlign="center">
										{subtitle}
									</H3>
								</SubtitleWrapper>
							</TitleWrapper>
							<FormWrapper>
								<Form downloadDocument={whitepaper_document} formId={hubspot_form_id} />
							</FormWrapper>
							<RightImageWrapper>
								<Image url={rightImage.src} alt={'right-image'} />
							</RightImageWrapper>
						</Wrapper>
					</Container>
				)
			}, imagesQuery)}
		/>
	)
}

export default DownloadWhitepaperForm
