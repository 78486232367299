import React from 'react'
import { AppContext } from '../AppProvider'

import Button from './Button'

const CTA = ({
  name,
  variant,
  modalBody,
  onClick,
}) => {
  const { openModal } = React.useContext(AppContext)
  return (
    <Button
      variant={variant || 'primary'}
      onClick={
        onClick
          ? onClick
          : () => openModal(modalBody)}>
      {name}
    </Button>
  )
}

export default CTA