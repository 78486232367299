import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import ClampLines from 'react-clamp-lines'

import _size from "lodash/size"
import _get from "lodash/get"
import _join from "lodash/join"
import _isString from "lodash/isString"

import H2 from "../components/H2"
import P from "../components/P"
import QuizCard from "../components/RoundedImgCard"
import Image from "../components/image"

import arrowIcon from "./../images/Icon-RightChev.svg"
import media from "../styles/media"
import utils from "../utils"

const CardTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  ${media.lessThan("tablet")`
    margin-bottom: 25px;
  `}

  > a {
    cursor: pointer;
  }
  display: ${props => (props.active ? "block" : "none")};
`

const Content = styled.div`
  padding: 1.5rem;
  border-left: 0.187rem solid ${props => props.theme.colors.light};
  border-right: 0.187rem solid ${props => props.theme.colors.light};
  border-bottom: 0.187rem solid ${props => props.theme.colors.light};
  background-color: ${props => props.variant === 'whitepaper' ? props.theme.colors.light : 'transparent'};
`

const BottomWrapper = styled.div`
  display: flex;
  margin-top: 2.2rem;
  flex-direction: column;
  ${media.greaterThan('desktop')`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}
`

const TagsWrapper = styled.span`
  display: flex;
  justify-content: flex-start;
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.687rem;
  line-height: 0.275;
  text-transform: uppercase;
  color: ${props => props.theme.colors.dark};
`

const ImgWrapper = styled.div`
  display: flex;
  > img {
    width: 100%;
    height: 11.25rem;
  }
`

const CaseStudyImgWrapper = styled.div`
  display: flex;
  > img {
    width: 100%;
  }
`

const ImgCover = styled.div`
  width: 100%;
  height: 180px;
  display: block;
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const ButtonWithArrowSmall = styled(Link)`
  display: flex;
  align-items: center;
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${props => props.theme.colors.black};
  background: transparent;
  margin-top: 1rem;
  ${media.greaterThan('desktop')`
    margin-top: 0;
  `}
`

const ArrowImg = styled.img`
  width: 8px;
  height: 13px;
  margin-left: 0.4rem;
`

const CardTemplate = ({ variant, thumbnail, title, text, link, tags, active }) => {
  return (
    <CardTemplateWrapper
      active={active}
    >
      {thumbnail && (
        <Link to={link}>
          <ImgWrapper>
            <ImgCover url={_get(thumbnail, "url")} />
          </ImgWrapper>
        </Link>
      )}
      <Content variant={variant}>
        <Link to={link}>
          <H2 variant="cardtitle">{title}</H2>
          {_isString(text)
            ? <ClampLines
              text={text}
              lines={2}
              buttons={false}
              ellipsis="..."
              innerElement={P}
            />
            : <RichText
              render={text}
              htmlSerializer={utils.htmlSerializer}
            />}
        </Link>
        <BottomWrapper>
          {_size(tags) > 0 && (
            <TagsWrapper>{_join(tags, ", ")}</TagsWrapper>
          )}
          {variant !== 'whitepaper' && (
            <ButtonWithArrowSmall to={link}>
              Read more
              <ArrowImg src={arrowIcon} alt="arrow" />
            </ButtonWithArrowSmall>
          )}
        </BottomWrapper>
      </Content>
    </CardTemplateWrapper>
  )
}

const CaseStudyCardTemplate = ({ variant, thumbnail, title, text, link, tags, active }) => {
  return (
    <CardTemplateWrapper
      active={active}
    >
      {thumbnail && (
        <Link to={link}>
          <CaseStudyImgWrapper>
            <Image url={_get(thumbnail, "url")} alt={title} />
          </CaseStudyImgWrapper>
        </Link>
      )}
      <Content variant={variant}>
        <Link to={link}>
          <H2 variant="cardtitle">{title}</H2>
          {_isString(text)
            ? <ClampLines
              text={text}
              lines={2}
              buttons={false}
              ellipsis="..."
              innerElement={P}
            />
            : <RichText
              render={text}
              htmlSerializer={utils.htmlSerializer}
            />}
        </Link>
        <BottomWrapper>
          {_size(tags) > 0 && (
            <TagsWrapper>{_join(tags, ", ")}</TagsWrapper>
          )}
          {variant !== 'whitepaper' && (
            <ButtonWithArrowSmall to={link}>
              Read more
              <ArrowImg src={arrowIcon} alt="arrow" />
            </ButtonWithArrowSmall>
          )}
        </BottomWrapper>
      </Content>
    </CardTemplateWrapper>
  )
}


const ArticleCard = ({ article, active }) => {
  const {
    thumbnail,
    heading,
    listing_description,
    _meta } = article || {}
  return <CardTemplate
    variant='article'
    thumbnail={thumbnail}
    title={heading}
    text={listing_description}
    link={utils.linkResolver(_meta)}
    tags={_get(_meta, 'tags', [])}
    active={active}
  />
}

const CaseStudyCard = ({ case_study, active }) => {
  const {
    body,
    page_title,
    banner_text,
    _meta } = case_study || {}
  return <CaseStudyCardTemplate
    variant='case_study'
    thumbnail={body[0].primary.background_image}
    title={page_title}
    text={banner_text}
    link={utils.linkResolver(_meta)}
    tags={_get(_meta, 'tags', [])}
    active={active}
  />
}

const WhitepaperCard = ({ whitepaper, active }) => {
  const {
    thumbnail,
    page_title,
    hero_text,
    _meta } = whitepaper || {}
  return <CardTemplate
    variant='whitepaper'
    thumbnail={thumbnail}
    title={page_title}
    text={hero_text}
    link={utils.linkResolver(_meta)}
    tags={_get(_meta, 'tags', [])}
    active={active}
  />
}

const Card = ({ variant, ...rest }) => {
  return ({
    article: <ArticleCard variant={variant} {...rest} />,
    whitepaper: <WhitepaperCard variant={variant} {...rest} />,
    quiz: <QuizCard variant={variant} {...rest} />,
    case_study: <CaseStudyCard variant={variant} {...rest} />,
  })[variant] || <ArticleCard variant={variant} {...rest} />
}

export default Card
