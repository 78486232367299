import React from 'react'

import BlockList from './BlockList'
import ImageTextPanel from './ImageTextPanel'
import styled from 'styled-components'

const OutterWrapper = styled.div`margin: auto;`
const BlockListAndRecImage = (props) => {
	const {
		type,
		title,
		repeatableFields,
		bottom_image,
		bottom_text,
		bottom_title,
		bottom_button_link,
		bottom_button_name,
		bottom_author
	} =
		props || {}

	const imageTextPanelExists = bottom_image && bottom_text && bottom_title && bottom_author

	return (
		<OutterWrapper>
			<BlockList title={title} repeatableFields={repeatableFields} noBottomPadding={imageTextPanelExists} />
			{imageTextPanelExists ? (
				<ImageTextPanel
					type={type}
					title={bottom_title}
					paragraph={bottom_text}
					image={bottom_image}
					buttonName={bottom_button_name}
					buttonLink={bottom_button_link}
					paragraphAuthor={bottom_author}
					imageTextPanelExists
				/>
			) : null}
		</OutterWrapper>
	)
}

export default BlockListAndRecImage
