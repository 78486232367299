// all measurements are to rem units.
const vars = {
  gap: { sm: 1.75, md: 1.75, lg: 1.75 },
  breakpoints: { mobile: 26.875, tablet: 48, desktop: 75, largeDesktop: 118 },
  headerHeight: { sm: 7.7, md: 7.7, lg: 7.7 },
  contentWidth: { sm: 40.25, md: 52.25, lg: 61.25 },
  mobileSideMenuWidth: { sm: 21.43, md: 21.43, lg: 21.43 }
}

export default vars

