import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _mapKeys from 'lodash/mapKeys'

import Button from './../Button'

import media from '../../styles/media'

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	max-width: 750px;
	margin: 3rem auto;
`

const FieldWrapper = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
	text-align: right;

	${media.lessThan('tablet')`
		text-align: left;
		flex-direction: column;
	`};
`

const StyledLabel = styled.label`
	font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.187rem;
	line-height: 2.6;
	vertical-align: top;
`

const StyledErrorMessage = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	height: 25px;
	display: flex;
	flex: 1 1 100%;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	color: red;

	${media.lessThan('tablet')`
		bottom: -1rem;
	`};
`

const StyledInputField = styled.input`
	width: 70%;
	height: 55px;
	padding: 25px;
	margin-left: 2.5rem;
	margin-bottom: 1.875rem;
	font-size: 1rem;
	line-height: 1.5rem;
	color: ${(props) => props.theme.colors.darkSecondary};
	border: 0.187rem solid ${(props) => props.theme.colors.light};

	${media.lessThan('tablet')`
		width: 100%;
		margin-left: 0;
		margin-bottom: 1rem;
	`};
`

const FormMessage = styled.div`
	font-size: 1rem;
	padding-top: 1rem;
	line-height: 1.5rem;
	font-weight: normal;
	text-align: right;
	color: ${(props) => (props.isError ? 'red' : props.theme.colors.white)};
`

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	${media.lessThan('tablet')`
		margin-top: 1rem;
		justify-content: center;
		> button { width: 100%; }
	`};
`

const SelfAssesmentForm = ({ formId, submitText }) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('Thank you!')
  const [isError, setIsError] = React.useState(false)

  const config = {
    initialValues: { firstname: '', lastname: '', email: '' },
  }

  const [formData, setFormData] = React.useState({
    values: config.initialValues,
    errors: config.initialValues
  })


  const handleSubmit = async () => {
    handleValidation(formData.values)

    const errors = _map(formData.errors, (value) => value)

    if (errors.length > 0) return

    const fields = _map(formData.values, (value, key) => ({ name: key, value: value }))
    console.log(fields)
    const data = {
      submittedAt: Date.now(),
      fields,
      context: {
        pageUri: 'www.redant.com/self-assesment',
        pageName: 'Self-Assesment | Red Ant'
      }
    }

    const xhr = new XMLHttpRequest()

    xhr.open('POST', `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${formId}`)
    xhr.setRequestHeader('Content-Type', 'application/json')

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const { inlineMessage } = JSON.parse(xhr.responseText) || {}
        setIsSubmitted(true)
        setSuccessMessage(inlineMessage)
        // reset form data
        setFormData({
          values: config.initialValues,
          errors: config.initialValues
        })
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        // Returns a 400 error the submission is rejected.
        setIsSubmitted(false)
        setIsError(true)
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        // Returns a 403 error if the portal isn't allowed to post submissions.
        setIsSubmitted(false)
        setIsError(true)
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        //Returns a 404 error if the formGuid isn't found
        setIsSubmitted(false)
        setIsError(true)
      }
    }

    xhr.send(JSON.stringify(data))
  }

  const handleValidation = (data) => {
    setFormData({
      values: data,
      errors: {
        ...validate(data)
      }
    })
  }

  const handleChange = (event) => {
    const name = _get(event, 'target.name')
    const value = _get(event, 'target.value')
    const newData = {
      ...formData.values,
      [name]: value
    }
    handleValidation(newData)
  }

  const validate = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  return (
    <StyledForm>
      <FieldWrapper key={'firstname'}>
        <StyledLabel htmlFor="firstname">First name</StyledLabel>
        <StyledInputField
          id={'firstname'}
          name={'firstname'}
          type={'text'}
          value={formData.values.firstname}
          onfocusout={handleChange}
          onChange={handleChange}
        />
        {formData.errors.firstname && <StyledErrorMessage>{formData.errors.firstname}</StyledErrorMessage>}
      </FieldWrapper>

      <FieldWrapper key={'lastname'}>
        <StyledLabel htmlFor="lastname">Last name</StyledLabel>
        <StyledInputField
          id={'lastname'}
          name={'lastname'}
          type={'text'}
          value={formData.values.lastname}
          onfocusout={handleChange}
          onChange={handleChange}
        />
        {formData.errors.lastname && <StyledErrorMessage>{formData.errors.lastname}</StyledErrorMessage>}
      </FieldWrapper>

      <FieldWrapper key={'email'}>
        <StyledLabel htmlFor="email">Email</StyledLabel>
        <StyledInputField
          id={'email'}
          name={'email'}
          type={'email'}
          value={formData.values.email}
          onfocusout={handleChange}
          onChange={handleChange}
        />
        {formData.errors.email && <StyledErrorMessage>{formData.errors.email}</StyledErrorMessage>}
      </FieldWrapper>

      <ButtonWrapper>
        <Button variant="primary" type="button" onClick={handleSubmit}>
          {submitText}
        </Button>
      </ButtonWrapper>

      {isSubmitted && <FormMessage>{successMessage}</FormMessage>}
      {isError && <FormMessage isError>Something went wrong. Please try again later.</FormMessage>}
    </StyledForm>
  )
}

export default SelfAssesmentForm
