import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const List = styled.ul`
  list-style-type: circle;
`

const ParentList = styled.div`
  padding-left: 1rem;
  ul {
    li {
      line-height: 1.5;
      padding: .2rem 0;
      position: relative;
      overflow: visible;
      list-style: none;
      ::before {
        display: block;
        position: absolute;
        left: -1.0625rem;
        top: 0.875rem;
        content: " ";
        width: 0.3125rem;
        height: 0.3125rem;
        border-radius: 50%;
        background-color: #EC1D24;
      }
    }
  }
`

const ListItem = (children) => {
  return (
    <ParentList>
      <List>
        {_.map(children, (item) => {
          return item
        })}
      </List>
    </ParentList>
  )
}

export default ListItem