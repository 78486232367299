import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import _size from 'lodash/size'
import _map from 'lodash/map'

import Container from '../Container'
import H2 from '../H2'
import P from '../P'
import Image from '../image'

import media from '../../styles/media'
import utils from '../../utils'

const TitleWrapper = styled.div`
  max-width: 864px;
  margin: 0 auto 3.5rem auto;
`

const IconsContainer = styled.div`
  max-width: 964px;
  margin: 0 auto;
	display: flex;
	flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

	${media.greaterThan('tablet')`
    flex-direction: row;
    align-items: baseline;
  `};

  ${media.greaterThan('desktop')`
    flex-direction: row;
    align-items: baseline;
  `};
`

const IconWrapper = styled.div`
  width: 100%;
  align-items: center;
	text-align: center;
  margin-bottom: 10px;
  ${media.greaterThan('tablet')`
    width: 50%;
    margin-bottom: 30px;
  `};

  ${media.greaterThan('desktop')`
    width: 33.3%;
    margin-bottom: 30px;
  `};

  &:hover { 
    opacity: 0.8;
  }
`

const IconSubtitleWrapper = styled.div`
	max-width: 192px;
  margin: 0 auto;
`

const ImageWrapper = styled.div`
  margin: 0 auto;
	max-width: ${({ maxWidth }) => maxWidth}px;
	> img {
		width: 100%;
		height: auto;
	}
`
const InnerWrapper = styled.div`
	${media.greaterThan('tablet')`
  padding-left: 5rem;
  padding-right: 5rem;
`};
`

const IconsListing = ({ type, title, subtitle, repeatableFields }) => {
  return (
    <Container type={type}>
      <InnerWrapper>
        <TitleWrapper>
          <H2 variant="heading" textAlign="center">
            {title}
          </H2>
          <P variant="subtitle" textAlign="center">
            {subtitle}
          </P>
        </TitleWrapper>
        {_size(repeatableFields) && (
          <IconsContainer>
            {_map(repeatableFields, ({ title, subtitle, link, icon_image }) => (
              <IconWrapper key={title}>
                <Link to={utils.linkResolver(link)}>
                  {icon_image && (
                    // icon images are provided double the actual design size.
                    // Therefore, the max-width is set to half the image width.
                    <ImageWrapper maxWidth={icon_image.dimensions.width / 2}>
                      <Image {...icon_image} />
                    </ImageWrapper>
                  )}
                  <P variant="secondary" textAlign="center">
                    {title}
                  </P>
                  <IconSubtitleWrapper>
                    <P variant="primary">{subtitle}</P>
                  </IconSubtitleWrapper>
                </Link>
              </IconWrapper>
            ))}
          </IconsContainer>
        )}
      </InnerWrapper>
    </Container>
  )
}

export default IconsListing
