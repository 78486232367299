import React from 'react'
import styled from 'styled-components'
import _get from 'lodash/get'

import { AppContext } from '../../../AppProvider'
import Image from './../../image'
import Container from './../../Container'
import media from './../../../styles/media'

const InnerContainer = styled.div`
  margin-bottom: 7.7rem; /* important */
  width: 100%;
  min-height: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 10px solid #fff;
  border-bottom: 10px solid #fff;
  background-color: #000;
  background-image: url("${({ backgroundImage }) => backgroundImage.url}");
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: screen;

  > img {
    width: 306px;
    margin-bottom: 48px;
    padding-top: 50px;
  }

  > h2 {
    max-width: 955px;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 2.0625rem;
    line-height: 2.5rem;
    color: #ffffff;
    margin-bottom: 43px;
    text-align: center;
  }
  > span {
    color: #FFFFFF;
    font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 8px;
  }

  > p {
    max-width: 887px;
    color: #FFFFFF;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.437rem;
    letter-spacing: 0;
    line-height: 1.875rem;
    margin-bottom: 75px;
    text-align: center;
  }

  ${media.greaterThan("tablet")`
    
  `}
`

const PromoBannerFlannels = ({
  type,
  logo_image,
  background_image,
  title,
  quote,
}) => {
  const { openModal } = React.useContext(AppContext)
	return (
		<Container
			type={type}
      fullWidth
      fullSectionWidth
      noPadding
		>
      <InnerContainer backgroundImage={background_image}>
        {logo_image && (
          <Image {...logo_image} />
        )}
        <h2>{title}</h2>
        <span>They said:</span>
        <p>{quote}</p>
      </InnerContainer>
		</Container>
	)
}

export default PromoBannerFlannels
