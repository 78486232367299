import React from 'react'

import Hero from '../components/slices/Hero'
import ImagesPanel from '../components/slices/ImagesPanel'
import ColumnsListing from '../components/slices/ColumnsListing'
import BlockList from '../components/slices/BlockList'
import FullWidthImagePanel from '../components/slices/FullWidthImagePanel'
import ColumnPanel from '../components/slices/ColumnPanel'
import ColoredBanner from '../components/slices/ColoredBanner'
import SignUpFormBanner from '../components/slices/SignUpFormBanner'
import InvertedColoredBoxesPanel from '../components/slices/InvertedColoredBoxesPanel'
import LargeImagePanel from '../components/slices/LargeImagePanel'
import InvertedColumns from '../components/slices/InvertedColumns'
import ImageTextPanel from '../components/slices/ImageTextPanel'
import InvertedBoxesPanel from '../components/slices/InvertedBoxesPanel'
import ContactForm from '../components/slices/ContactForm'
import HeroWithButton from '../components/slices/HeroWithButton'
import BlockListAndRecImage from '../components/slices/BlockListAndRecImage'
import IconBanner from '../components/slices/IconBanner'
import FullPageIconPanel from '../components/slices/FullPageIconPanel'
import ListPanel from '../components/slices/ListPanel'
import LogosPanel from '../components/slices/LogosPanel'
import ResourceListing from '../components/slices/ResourceListing'
import Locations from '../components/slices/Locations'
import ContactBlock from '../components/slices/ContactBlock'
import TeamMembersList from '../components/slices/TeamMembersList'
import BackgroundImageWithText from '../components/slices/BackgroundImageWithText'
import LargeContentWithSideImages from '../components/slices/LargeContentWithSideImages'
import TextBanner from '../components/slices/TextBanner'
import Columns from '../components/slices/Columns'
import DownloadWhitepaperForm from '../components/slices/DownloadWhitepaperForm'
import ColumnOfBanners from '../components/slices/ColumnOfBanners'
import ListOfCTAs from '../components/slices/ListOfCTAs'
import FreeTextPanel from '../components/slices/FreeTextPanel'
import PaperWithTitle from '../components/slices/PaperWithTitle'
import BackgroundImagePanel from '../components/slices/BackgroundImagePanel'
import Survey from '../components/slices/Survey'
import RetailersPanel from '../components/slices/RetailersPanel'
import IconsListing from '../components/slices/IconsListing'
import HubspotForm from '../components/slices/HubspotForm'
import LargeImgWithOverlapBanner from '../components/slices/LargeImgWithOverlapBanner'
import ImageLinkBanner from '../components/slices/ImageLinkBanner'
import PromoBanner from '../components/slices/PromoBanners/PromoBanner'
import PromoBannerFlannels from '../components/slices/PromoBanners/PromoBannerFlannels'
import PromoBannerFurnitureVillage from '../components/slices/PromoBanners/PromoBannerFurnitureVillage'
import CaseStudyListing from '../components/slices/CaseStudyListing'
import LongTextBanner from '../components/slices/LongTextBanner'
import ImageOnly from '../components/slices/ImageOnly'
import IconBanner2 from '../components/slices/IconBanner2'
import Quote from '../components/slices/Quote'
import TitleImageButton from '../components/slices/TitleImageButton'

const sliceRenderer = ({ type, key, primary, fields, __typename, ...rest }) => {
	const options = {
		type,
		...rest,
		...primary,
		key,
		typename: __typename,
		repeatableFields: fields
	}

	return (
		{
			hero: <Hero {...options} />,
			images_panel: <ImagesPanel {...options} />,
			block_list: <BlockList {...options} />,
			title_and_image: <FullWidthImagePanel {...options} />,
			article: <ColumnPanel {...options} />,
			colored_banner: <ColoredBanner {...options} />,
			signup_form_banner: <SignUpFormBanner {...options} />,
			inverted_cta_boxes: <InvertedColoredBoxesPanel {...options} />,
			columns_list: <ColumnsListing {...options} />,
			large_image_panel: <LargeImagePanel {...options} />,
			two_columns_panel: <InvertedColumns {...options} />,
			image_and_quote_box: <ImageTextPanel {...options} />,
			inverted_cta_boxes_with_arrow: <InvertedBoxesPanel {...options} />,
			contact_form: <ContactForm {...options} />,
			hero_with_button: <HeroWithButton {...options} />,
			text_with_rectangle_image: <BlockListAndRecImage {...options} />,
			icon_banner: <IconBanner {...options} />,
			resources_listing: <ResourceListing {...options} />,
			circle_numbers: <IconBanner {...options} />,
			full_page_icons_panel: <FullPageIconPanel {...options} />,
			list_panel: <ListPanel {...options} />,
			mini_logos_panel: <LogosPanel {...options} />,
			locations: <Locations {...options} />,
			contact_block: <ContactBlock {...options} />,
			team_members_list: <TeamMembersList {...options} />,
			background_image_with_text: <BackgroundImageWithText {...options} />,
			image___text: <BackgroundImageWithText {...options} />,
			large_content: <LargeContentWithSideImages {...options} />,
			text_banner: <TextBanner {...options} />,
			columns: <Columns {...options} />,
			download_whitepaper_form: <DownloadWhitepaperForm {...options} />,
			list_of_images_with_text: <ColumnOfBanners {...options} />,
			list_of_ctas: <ListOfCTAs {...options} />,
			rich_text_area: <FreeTextPanel {...options} />,
			paper_with_title: <PaperWithTitle {...options} />,
			background_image_panel: <BackgroundImagePanel {...options} />,
			survey: <Survey {...options} />,
			retailers_panel: <RetailersPanel {...options} />,
			icons_listing: <IconsListing {...options} />,
			hubspot_form: <HubspotForm {...options} />,
			down_arrow_with_overlap_banner: <LargeImgWithOverlapBanner {...options} />,
			image_link_banner: <ImageLinkBanner {...options} />,
			promo_banner: <PromoBanner {...options} />,
			flannels_promo_banner: <PromoBannerFlannels {...options} />,
			furniture_village_promo_banner: <PromoBannerFurnitureVillage {...options} />,
			case_study_listing: <CaseStudyListing {...options} />,
			long_text_banner: <LongTextBanner {...options} />,
			image_only: <ImageOnly {...options} />,
			icon_banner_2: <IconBanner2 {...options} />,
			quote: <Quote {...options} />,
			title_image_button: <TitleImageButton {...options} />,
		}[type] || <div />
	)
}

export default sliceRenderer
