import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { RichText } from 'prismic-reactjs'

import H3 from './H3'
import Button from './Button'
import theme from '../styles/theme'
import utils from '../utils'
import media from '../styles/media'

import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const BoxWrapper = styled.div`
		display: flex;
		flex: 1;
		flex-direction: column;
		background: ${({ backgroundColor, theme }) =>
		backgroundColor === 'none'
			? 'transparent'
			: backgroundColor === 'dark' ? theme.colors.secondary : theme.colors.light};
		padding: ${({ backgroundColor }) => backgroundColor === 'none' ? '0' : '1.5rem'};
		
		${media.greaterThan('tablet')`
      padding: 2.812rem;
    `};
	`
const TitleWrapper = styled.div`
		display: flex;
		flex-direction: column;
		flex: 1;
		padding-bottom: ${({ backgroundColor }) => backgroundColor === 'none' ? 0 : `1rem`};
		${media.greaterThan('tablet')`
			padding-bottom: 2rem;
    `};
	`

const ButtonWrapper = styled.div`
		display: flex;
		justify-content: center;
		${media.greaterThan('tablet')`
      justify-content: ${({ alignDirection }) => alignDirection === 'left' ? 'flex-start' : 'flex-end'};
    `};
	`
const Line = styled.div`
		width: 100%;
		max-width: 115px;
		height: .25rem;
		background: ${({ color, theme }) => (color === 'dark' ? theme.colors.dark : theme.colors.white)};
		margin-bottom: 1rem;
		-webkit-transition: max-width 0.25s ease-in-out;
		transition: max-width 0.25s ease-in-out;
	`

const Box = ({
	backgroundColor,
	textColor,
	title,
	text,
	link,
	buttonText,
	buttonVariant,
	buttonAlign,
	trackingLabel
}) => {

	return (
		<BoxWrapper backgroundColor={backgroundColor}>
			<TitleWrapper backgroundColor={backgroundColor}>
				<H3 variant="boxTitle" textAlign="left" color={textColor}>
					{title}
				</H3>
				<Line color={textColor} />
				<RichText render={text} htmlSerializer={utils.htmlSerializer} />
			</TitleWrapper>
			<ButtonWrapper alignDirection={buttonAlign}>
				<Button
					href={utils.linkResolver(link)}
					variant={buttonVariant}
					onClick={(e) =>
						trackCustomEvent({
							category: 'CTA',
							action: 'Click',
							label: trackingLabel
						})}
				>
					{buttonText}
				</Button>
			</ButtonWrapper>
		</BoxWrapper>
	)
}

Box.propTypes = {
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.array,
	link: PropTypes.object,
	buttonText: PropTypes.string,
	buttonVariant: PropTypes.string,
	buttonAlign: PropTypes.string,
	trackingLabel: PropTypes.string,
}

export default Box
