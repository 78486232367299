import React from "react"
import styled from "styled-components"
import _size from "lodash/size"
import _map from "lodash/map"

import H2 from './../H2'
import H3 from './../H3'
import H4 from './../H4'
import P from './../P'
import Container from './../Container'
import Image from './../Image'

import media from "../../styles/media"
import utils from "../../utils"

import linkedInIcon from "../../images/Icon-LI.svg"


const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`
const TitleWrapper = styled.div`
  max-width: 56.25rem;
  margin: 0 auto 3rem auto;
`
const ProfileCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 59.25rem;
  margin: 0 auto;
  ${media.lessThan("tablet")`
    flex-direction: column;
  `}
  ${media.lessThan("desktop")`
    max-width: 34rem;
    flex-wrap: wrap;
    justify-content: space-arround;
    > div { flex: 1 1 50%; }
  `}
`
const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
`
const ProfileCardImage = styled.div`
  margin: 0 auto;
`

const ProfileContent = styled.div`
  margin-top: 1.562rem;
`
const SocialMediaLink = styled.a`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`
const LinkedInImg = styled.img`
  padding-right: 0.5rem;
`

const TeamMembersList = ({
  type,
  title,
  sub_title,
  repeatableFields
}) => {
  return (
    <Container type={type} backgroundColor={`light`}>
      <Wrapper>
        <TitleWrapper>
          <H2 variant={`bigHeading`}>{title}</H2>
          <H3 variant={`secondary`}>{sub_title}</H3>
        </TitleWrapper>
        {_size(repeatableFields) > 0 && (
          <ProfileCardsWrapper>
            {_map(repeatableFields, ({
              name,
              surname,
              role,
              profile_image,
              social_media_link
            }, index) => {
              return (
                <ProfileCard key={index}>
                  <ProfileCardImage>
                    <Image {...profile_image} />
                  </ProfileCardImage>
                  <ProfileContent>
                    <H4 variant={`secondary`}>{`${name} ${surname}`}</H4>
                    <P>{role}</P>
                    <SocialMediaLink href={utils.linkResolver(social_media_link)} target="_blank">
                      <LinkedInImg src={linkedInIcon} alt="linkedIn icon" />
                      {`Follow ${name}`}
                    </SocialMediaLink>
                  </ProfileContent>
                </ProfileCard>
              )
            })}
          </ProfileCardsWrapper>
        )}
      </Wrapper>
    </Container>
  )
}

export default TeamMembersList