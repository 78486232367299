import React from "react"
import _map from "lodash/map"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"

import Image from "../Image"
import utils from "../../utils"
import media from "../../styles/media"
import Container from "../Container"

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${media.lessThan("tablet")`
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

const ColumnWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  margin: 0 1.8rem;
  max-width: 22rem;
  ${media.lessThan("tablet")`
    max-width: initial;
    margin-bottom: 1.8rem;
    margin-left: 0;
    margin-right: 0;
  `}
`

const ColumnTitle = styled.h3`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.562rem;
  line-height: 2.062rem;
  margin-bottom: 0.6rem;

  &::after { 
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    margin-top: 1.8rem;
    background-color: #DDDDDD;
  }
`

const ItemSubTitle = styled.h4`
  font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.062rem;
  line-height: 1.25rem;
  padding: 0 2.8rem;
  margin-bottom: 1.2rem;
`
const ColumnIconWrapper = styled.div`
  margin: 2.5rem auto;
  ${media.lessThan("tablet")`
    margin: .5rem auto;
  `}
  > img {
    width: auto;
    max-width: 267px;
  }
`
const ContentWrapper = styled.div`
  padding: 0;
  ${media.greaterThan('mobile')`
    padding: 0 3rem;
  `}
`

const Column = ({ title, sub_title, content, icon }) => {
  return (
    <ColumnWrapper>
      <ColumnTitle>{title}</ColumnTitle>
      {icon && (
        <ColumnIconWrapper>
          <Image {...icon} />
        </ColumnIconWrapper>
      )}
      <ItemSubTitle>{sub_title}</ItemSubTitle>
      <ContentWrapper>
        <RichText render={content} htmlSerializer={utils.htmlSerializer} />
      </ContentWrapper>

    </ColumnWrapper>
  )
}

const Columns = ({ type, repeatableFields }) => {
  return (
    <Container type={type}>
      <ColumnsWrapper>
        {_map(repeatableFields, (column, index) => <Column key={index} {...column} />)}
      </ColumnsWrapper>
    </Container>
  )
}

export default Columns