import React from 'react'
import styled from 'styled-components'

import utils from '../../utils'
import media from '../../styles/media'

import Container from '../Container'
import H1 from '../H1'

const Box = styled.div`
  height: 819px;
  max-width: 831px;
  box-shadow: 0 30px 30px 0 rgba(58,58,74,0.1);
  margin: 0 auto;
  > .smcx-widget {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  > .smcx-embed.smcx-hide-branding > .smcx-iframe-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`

const TitleWrapper = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 0;
  ${media.greaterThan("tablet")`
    padding-top: 1.5rem;
    padding-bottom: 0;
  `}
  ${media.greaterThan("desktop")`
    padding-top: 3rem;
    padding-bottom: 1.5rem;
  `}
`

const Survey = ({ title }) => {
  React.useEffect(() => {
    let script = document.createElement("script")
    script.type = "text/javascript"
    script.src = ["https:" === window.location.protocol ? "https://" : "http://", "widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd2g2hQGAE13BgfxG6O_2FdLqBeN3kQrZ16XDaW90o6kO_2BQ.js"].join("")
    script.id = "smcx-sdk"
    script.async = true
    document.body.appendChild(script)
  },
    []
  )

  return (
    <Container backgroundColor={'light'} noPadding>
      <TitleWrapper>
        <H1 as='h2' variant='primary' color='dark' textAlign='center'>
          {title}
        </H1>
      </TitleWrapper>
      <Box>
        <div id="smcx-sdk" />
      </Box>
    </Container>
  )
}

export default Survey