import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { variant } from 'styled-system'
import _get from 'lodash/get'

import theme from '../styles/theme'
import rgba from '../utils/rgba'

const LinkPrimary = {
	fontFamily: 'RobotoBold',
	fontSize: '1.187rem',
	lineHeight: 1.7,
	cursor: 'pointer',
	color: theme.colors.dark,
	textDecoration: 'underline',
	'&:hover': {
		color: rgba(theme.colors.dark, 0.8)
	}
}

const LinkSecondary = {}

const LinkVariant = styled(Link)(
	variant({
		variants: {
			primary: LinkPrimary,
			secondary: LinkSecondary
		}
	})
)

const ExternalLinkVariant = styled('a')(
	variant({
		variants: {
			primary: LinkPrimary,
			secondary: LinkSecondary
		}
	})
)

const CustomLink = (link) => {
	return _get(link, 'external') ? (
		<ExternalLinkVariant href={link.to} target="_blank" {...link} />
	) : (
		<LinkVariant {...link} />
	)
}

export default CustomLink
