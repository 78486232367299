import _get from 'lodash/get'
import _find from 'lodash/find'

const exceptions = []

const linkResolver = link => {
  const { url } = link || {}

  if (url) return url

  let { type, uid } = _get(link, '_meta', {})

  if (!uid || !type) {
    uid = _get(link, 'uid')
    type = _get(link, 'type')
  }

  const exception = _find(exceptions, { uid })

  if (exception) return exception.url

  return (
    {
      solutions: `/retailos/${uid}`,
      product_page: `/retailos/${uid}`,
      blog_post: `/blog/${uid}`,
      resource: `/${uid}`,
      whitepaper: `/research/${uid}`,
      home: `/`,
      retailos: `/${uid}`,
      legal: `/${uid}`,
      contact_us: `/${uid}`,
      pharma_os: `/pharmaos`,
      about_us: `/${uid}`,
      company: `/about/${uid}`,
      self_assesment_landing: `/${uid}`,
      self_assesment_persona: `/self-assesment/${uid}`,
      case_study: `/case-study/${uid}`,
      case_study_list: `/${uid}`,
    }[type] || '/'
  )
}

export default linkResolver
