import React from 'react'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import _get from 'lodash/get'

import { AppContext } from '../../../AppProvider'
import H1 from '../../H1'
import H2 from '../../H2'
import Image from '../../Image'
import Container from '../../Container'
import media from '../../../styles/media'
import utils from '../../../utils'

const InnerContainer = styled.div`
  margin: 0 auto;
  ${media.greaterThan('tablet')`
    max-width: 1391px;
  `};
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GridItem = styled.div`
  width: 100%;
  ${media.greaterThan('tablet')`
    width: 50%;
  `};
`

const AwardsListGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const AwardsListGridItem = styled.div`
  width: 100%;
  &:first-child {
    border-bottom: 3px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  ${media.greaterThan('tablet')`
    width: 50%;
      &:first-child {
        border-right: 3px solid black;
        border-bottom: none;
      }
  }`}
`

const AwardsListText = styled.div`
  & p {
    padding-bottom: 0;
    padding-right: 1rem;
    text-align: center;
    ${media.greaterThan('tablet')`
    text-align: left;
    `}
  }
`

const DescriptionText = styled.div`
  & p {
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    ${media.greaterThan('tablet')`
      font-size: 1.2rem;
    `}
  }
`

const LogoImage = styled.img`
  width: 80%;
  max-width: 160px;
  margin: 0 auto;
  display: inherit;
`

const Title = styled(H1)`
  ${media.greaterThan('tablet')`
    font-size: 3.5rem;
  `}
`

const AwardsTitle = styled(H2)`
  margin-bottom: 0.5rem;
  font-family: RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem !important;
`

const ModalButton = styled.button`
  z-index: 8;
  background-color: transparent;
  outline: none;
  border: none;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 188px;
  > img {
    width: 100%;
  }
  ${media.greaterThan('tablet')`
    position: absolute;
    top: 0px;
    left: 116px;
    &:hover {
      > img {
        filter: drop-shadow(4px 12px 10px rgba(0,0,0,0.5));
      }
    }
  `}
`

const GridItemWithSupportingImage = styled(GridItem)`
  position: relative;
`

const SupportingImageContainer = styled.div`
  display: none;
  ${media.greaterThan('tablet')`
    display: block;
  `}
`

const PromoBanner = ({
  type,
  description,
  awards_title,
  awards_list,
  awards_logo,
  background_image,
  background_gradient_left,
  background_gradient_right,
  title,
  vimeo_video_id,
  vimeo_modal_title,
  supporting_image,
  video_button_image
}) => {
  const { openModal } = React.useContext(AppContext)
	return (
		<Container
			type={type}
			fullWidth
      backgroundColor={'custom'}
      customBackground={`url(${background_image.url}), linear-gradient(45deg, ${background_gradient_left} 0%, ${background_gradient_right} 100%)`}
      backgroundBlendMode={'screen'}
		>
      <InnerContainer>
        <GridContainer>
          <GridItem>
            <Title variant="primary">{title}</Title>
            <DescriptionText>
              <RichText render={description} htmlSerializer={utils.htmlSerializer} />
            </DescriptionText>
            <AwardsTitle variant="primary">
              {awards_title}
            </AwardsTitle>
            <AwardsListGridContainer>
              <AwardsListGridItem>
                <AwardsListText>
                  <RichText render={awards_list} htmlSerializer={utils.htmlSerializer} />
                </AwardsListText>
              </AwardsListGridItem>
              <AwardsListGridItem>
                <LogoImage src={awards_logo.url} alt={awards_logo.alt} />
              </AwardsListGridItem>
            </AwardsListGridContainer>
          </GridItem>
          <GridItemWithSupportingImage>
              <ModalButton
                onClick={() =>
                  openModal({
                    type: 'iframe',
                    main: <iframe src={`https://player.vimeo.com/video/${vimeo_video_id}`} width="604" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                  })
                }
              >
                <Image {...video_button_image} />
              </ModalButton>
              <SupportingImageContainer>
                <Image {...supporting_image} />
              </SupportingImageContainer>
          </GridItemWithSupportingImage>
        </GridContainer>
        
      </InnerContainer>
		</Container>
	)
}

export default PromoBanner
