import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import _get from "lodash/get"

import media from "../../styles/media"
import useWindowSize from '../../hooks/useWindowSize'
import Container from '../../components/Container'
import vars from '../../styles/vars'
import utils from '../../utils'

const ImgWrapper = styled.div`
  display: flex;
  max-width: 79.25rem;
  margin: auto;
  img {
    width: 100%;
  }
`

const ImgCover = styled.div`
  width: 100%;
  height: 26.5rem;
  display: block;
  background-image: url("${props => props.url}");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 1.875rem;
`

const ImageLinkBanner = (props) => {
  const {
    banner_image_desktop,
    banner_image_mobile,
    banner_link,
  } = props
  const windowSize = useWindowSize()
  const imageUrl = windowSize < vars.breakpoints.mobile * 16 && banner_image_mobile ? _get(banner_image_mobile, "url") : _get(banner_image_desktop, "url")
  return (
    <Container noBottomPadding>
      <Link to={utils.linkResolver(banner_link)}>
        <ImgWrapper>
          <ImgCover url={imageUrl} />
        </ImgWrapper>
      </Link>
    </Container>
  )
}

export default ImageLinkBanner