import React from 'react'
import styled from 'styled-components'
import _size from 'lodash/size'
import _map from 'lodash/map'
import { RichText } from 'prismic-reactjs'

import utils from '../../utils'
import media from '../../styles/media'
import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'
import P from '../P'

const InnerWrapper = styled.div`
  ${media.greaterThan('tablet')`
    padding-left: 5rem;
    padding-right: 5rem;
  `}
`
const ListWrapper = styled.div`
display: flex;
flex-direction: column;
padding-top: 2rem;
${media.greaterThan('mobile')`
  flex-direction: row;
  flex-wrap: wrap;
`}
`
const ListParent = styled.div`
  padding: 1rem;
  flex: 1 0 50%;
  ${media.greaterThan('mobile')`
    padding: 1rem 1rem 0 1rem;  `}
`
const ListChild = styled.div`
  padding-top: 1rem;
`

const ListPanel = ({ title, subtitle, repeatableFields }) => {
  return (
    <Container backgroundColor={'light'}>
      <InnerWrapper>
        <H2 variant='heading' textAlign='center'>{title}</H2>
        <P variant='subtitle' textAlign='center'>{subtitle}</P>
        {_size(repeatableFields) > 0 &&
          <ListWrapper>
            {_map(repeatableFields, ({ list_title, list }) => {
              return (
                <ListParent key={list_title}>
                  <H3 variant='listingTitle'>{list_title}</H3>
                  <RichText render={list} htmlSerializer={utils.htmlSerializer} />
                </ListParent>
              )
            })}
          </ListWrapper>
        }
      </InnerWrapper>
    </Container>
  )
}

export default ListPanel