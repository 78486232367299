import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Container from '../Container'
import H2 from '../H2'
import H3 from '../H3'
import Button from '../Button'
import media from '../../styles/media'

import utils from './../../utils'

const Wrapper = styled.div`
	${media.greaterThan('mobile')`
    padding: 0 5rem;
  `};
`
const TitleWrapper = styled.div`
	max-width: 64rem;
	margin: 0 auto;
	padding-bottom: 1.6rem;
`
const SubTitleWrapper = styled.div`
	max-width: 68rem;
	margin: 0 auto;
	padding-bottom: 1.9rem;
`
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	text-align: center;
	> button {
		min-width: 200px;
	}
	> a {
		min-width: 222px;
	}
`

const ColoredBanner = (props) => {
const { type, title, text, button_name, button_link, isOverflowToTop = false } = props
	return (
		<Container type={type} isOverflowToTop={isOverflowToTop} backgroundColor={`dark`}>
			<Wrapper>
				<TitleWrapper>
					<H2 variant="heading" color="light" textAlign="center">
						{title}
					</H2>
				</TitleWrapper>
				<SubTitleWrapper>
					<H3 variant="boxTitle" color="light" textAlign="center">
						{text}
					</H3>
				</SubTitleWrapper>
				{(button_name && button_link) && (
					<ButtonWrapper>
						<Button href={utils.linkResolver(button_link)} variant="secondary">
							{button_name}
						</Button>
					</ButtonWrapper>
				)}
			</Wrapper>
		</Container>
	)
}

export default ColoredBanner
