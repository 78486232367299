import styled from 'styled-components'
import { variant } from 'styled-system'

import theme from '../styles/theme'

const H1Variant = styled('h1')(
  variant({
    variants: {
      primary: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.937rem','1.937rem','3.5rem', '3.75rem'],
        lineHeight: 1.25
      },
      secondary: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: [ '1.187rem', '1.187rem', '1.575rem' ],
				lineHeight: 1.2
      },
      richTextFutura: {
        fontFamily: 'FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        fontSize: ['1.937rem','1.937rem','3.5rem', '3.75rem'],
        lineHeight: 1.25
      },
      richTextRoboto: {
        fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
				fontSize: [ '1.187rem', '1.187rem', '1.575rem' ],
				lineHeight: 1.2
      },
    }
  })
)

const H1 = styled(H1Variant)`
  color: ${props => props.color === 'light' ? theme.colors.white : theme.colors.dark};
  text-align: ${props => props.textAlign};
  margin-bottom: 1.5rem;
`
export default H1