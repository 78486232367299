import styled from 'styled-components'
import theme from '../styles/theme'
import media from '../styles/media'

const Paper = styled.div`
-webkit-box-shadow: 0px 7px 24px -9px rgba(0,0,0,0.23);
-moz-box-shadow: 0px 7px 24px -9px rgba(0,0,0,0.23);
  box-shadow: 0px 7px 24px -9px rgba(0,0,0,0.23);
  background-color: ${theme.colors.white};
  padding: 2rem;
  max-width: 52rem;
  margin: 0 auto;
  ${media.greaterThan('mobile')`
    padding: 3rem 2rem 2rem 2rem;
  `}
`

export default Paper