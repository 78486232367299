import React from 'react'
import styledMedia from 'styled-media-query'
import _get from 'lodash/get'
import styled from 'styled-components'

import Container from '../Container'
import SelfAssesmentForm from '../Forms/SelfAssesmentForm'
import DownloadWhitepaperForm from '../Forms/DownloadWhitepaperForm'
import H2 from '../H2'
import H3 from '../H3'

import media from '../../styles/media'

const Wrapper = styled.div`
	position: relative;
	${media.lessThan('tablet')`
    padding: 0 1.25rem;
  `};
`

const TitleWrapper = styled.div`
	text-align: center;
	padding: 0 1.375rem;
	max-width: 950px;
	margin: 0 auto;
	z-index: 0;
`

const SubtitleWrapper = styled.div`padding: 0 4.4rem;`

const FormWrapper = styled.div`
  position: relative;
`

const HubspotForm = ({
  type,
  title,
  subtitle,
  form_type,
  hubspot_form_id,
  submit_button_text
}) => {
  return (
    <Container type={type}>
      <Wrapper>
        <TitleWrapper>
          {title && (
            <H2 variant="heading" textAlign="center">
              {title}
            </H2>
          )}
          {subtitle && (
            <SubtitleWrapper>
              <H3 variant="subtitle" textAlign="center">
                {subtitle}
              </H3>
            </SubtitleWrapper>
          )}
        </TitleWrapper>
        <FormWrapper>
          {form_type === 'Self-Assesment' && <SelfAssesmentForm formId={hubspot_form_id} submitText={submit_button_text} />}
        </FormWrapper>
      </Wrapper>
    </Container>
  )
}

export default HubspotForm
