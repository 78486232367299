import sliceRenderer from './sliceRenderer'
import htmlSerializer from './htmlSerializer'
import linkResolver from './linkResolver'
import rgba from './rgba'

const utils = {
  sliceRenderer,
  linkResolver,
  htmlSerializer,
  rgba
}

export default utils
