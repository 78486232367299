import React from "react"
import styled, { css } from "styled-components"
import vars from "../styles/vars"
import theme from "../styles/theme"
import media from "../styles/media"

const Section = styled.section`
  overflow: hidden;
  background: ${props => props.backgroundColor};
  background-blend-mode: ${props => props.backgroundBlendMode || 'normal'};
  background-repeat: no-repeat;
  background-position: center top;
  background-image: ${props => (props.backgroundCover ? `url(${props.backgroundImageUrl})` : "" )};
  background-size: ${props => (props.backgroundCover ? "cover" : "" )};
  margin-top: ${props =>
    props.isOverflowToTop ? `-${vars.headerHeight.lg}rem` : ""};
  padding-top: ${props =>
    props.isOverflowToTop ? `${vars.headerHeight.lg}rem` : ""};
  padding-top: ${props =>
    props.noTopPadding ? "0px" : ""};
  padding: ${props => (props.noPadding ? "0px" : "3.53rem 0")};
  
  ${media.lessThan("tablet")`
    padding: ${props => (props.noPadding ? "0px" : "40px 0")};
    margin-top: ${props =>
      props.isOverflowToTop ? `-${vars.headerHeight.sm}rem` : ""};
    padding-top: ${props =>
      props.isOverflowToTop ? `${vars.headerHeight.sm}rem` : ""};
    padding-top: ${props =>
      props.noTopPadding ? "0px" : ""};
    padding-bottom: ${props =>
        props.noBottomPadding || props.noPadding ? "0" : "3.53rem"};
  `}
 
  ${media.between("tablet", "desktop")`
    padding: ${props => (props.noPadding ? "0px" : "3.53rem 0")};
    margin-top: ${props =>
      props.isOverflowToTop ? `-${vars.headerHeight.md}rem` : ""};
    padding-top: ${props =>
      props.isOverflowToTop ? `${vars.headerHeight.md}rem` : ""};
    padding-top: ${props =>
      props.noTopPadding ? "0px" : ""};
  `}

  ${media.greaterThan("tablet")`
    padding: ${props => (props.noPadding ? "0px" : "3.53rem 0")};
    margin-top: ${props =>
      props.isOverflowToTop ? `-${vars.headerHeight.lg}rem` : ""};
    padding-top: ${props =>
      props.isOverflowToTop ? `${vars.headerHeight.lg}rem` : ""};
    padding-top: ${props =>
      props.noTopPadding ? "0px" : ""};
    padding-bottom: ${props =>
      props.noBottomPadding || props.noPadding ? "0" : "3.53rem"};
  `}
`

const Content = styled.div`
  position: relative;
  ${props =>
    props.fullSectionWidth
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100% - ${vars.gap.lg}rem);
        `};
  max-width: ${props => (props.fullWidth ? "initial" : "1270px")};
  margin: 0 auto;

  text-align: ${({ centerContent }) => centerContent ? 'center' : 'initial'};
  ${media.lessThan("tablet")``}
 
  ${media.between("tablet", "desktop")``}
 
  ${media.greaterThan("desktop")``}
`
const getBackgroundColor = (color, customBackground) =>
  ({
    dark: theme.colors.primary,
    light: theme.colors.light,
    none: "transparent",
    custom: customBackground
  }[color] || "initial")

const Container = ({
  type,
  backgroundColor,
  isOverflowToTop,
  fullWidth,
  fullSectionWidth,
  noPadding,
  noBottomPadding,
  noTopPadding,
  centerContent,
  children,
  customBackground,
  backgroundBlendMode,
  backgroundImageUrl,
  backgroundCover,
  dimensions
}) => {
  return (
    <Section
    type={type}
    aria-label="TBD"
    backgroundColor={getBackgroundColor(backgroundColor, customBackground)}
    isOverflowToTop={isOverflowToTop}
    noPadding={noPadding}
    noBottomPadding={noBottomPadding}
    noTopPadding={noTopPadding}
    backgroundBlendMode={backgroundBlendMode}
    backgroundImageUrl={backgroundImageUrl}
    backgroundCover={backgroundCover}
    dimensions={dimensions}
    >
      <Content
        fullWidth={fullWidth}
        fullSectionWidth={fullSectionWidth}
        centerContent={centerContent}>
        {children}
      </Content>
    </Section>
  )
}

export default Container
