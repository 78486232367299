import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import _ from 'lodash'
import qs from 'query-string'
import { withPreview } from 'gatsby-source-prismic-graphql'
import styled from 'styled-components'


import Masonry from 'react-masonry-css'

import Container from '../Container'
import Card from '../Card'
import Button from '../Button'

import media from '../../styles/media'

const TagsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: -1.25rem;
  margin-bottom: 1.25rem;

  > span {
    display: flex;
    font-family: FuturaBTBold, Futura, RobotoBold, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.31rem;
    line-height: 1.4;
    margin: auto;
    padding-bottom: 1rem;
    ${media.greaterThan('mobile')`
      margin: 0;
      margin-right: 1.5rem;
      padding-bottom: 0;
    `}
  }

  > button {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    ${media.greaterThan('mobile')`
      width: auto;
    `}
  }

  > button + button {
    ${media.greaterThan('mobile')`
      margin-left: 0.93rem;
    `}
  }
  ${media.greaterThan('mobile')`
    flex-direction: row;
  `}
`

const resourcesQuery = graphql`
  query ResourcesQuery {
    articles: prismic {
      allBlog_posts(sortBy: publish_date_DESC) {
        edges {
          node {
            ...Blog_article
          }
        }
      }
    }
    whitepapers: prismic {
      allResources {
        edges {
          node {
            whitepapers {
              whitepaper_page {
                ... on PRISMIC_Whitepaper {
                  thumbnail
                  page_title
                  publish_date
                  hero_title
                  hero_text
                  _meta {
                    type
                    uid
                    tags
                  }
                }
              }
            }
          }
        }
      }
    }
    quiz: prismic {
      allResources {
        edges {
          node {
            quiz_publish_date
            quiz_resource_card_image
            show_quiz_card
            mobile_quiz_image
            quiz_link {
              _linkType
              ...LinkSelfAssesmentLandingFragment
              ...LinkSelfAssesmentPersonaFragment
              ...LinkSolutionFragment
              ...LinkLegalFragment
              ...LinkResourceFragment
              ...LinkContactUsFragment
              ...LinkPlatformFragment
              ...LinkCompanyFragment
              ...LinkArticleFragment
              ...LinkAboutFragment
              ...LinkRetailOSFragment
              ...LinkWhitepaperFragment
              ...LinkExternalFragment
              ...LinkFileFragment
            }
          }
        }
      }
    }
  }
`


const TagsFilter = ({ tags, handleClick }) => (
  <TagsWrapper>
    <span>Showing</span>
    {_.map(tags, (tag, index) => (
      <Button
        key={index}
        variant='checkButton'
        checked={_.get(tag, 'active', false)}
        onClick={() => handleClick(tag)}
      >
        {_.get(tag, 'name')}
      </Button>
    ))}
  </TagsWrapper>
)

const ResourceListing = ({ type, location }) => {
  const { filter } = qs.parse(location.search, { arrayFormat: 'comma' })

  let filters = filter ? _.isArray(filter) ? filter : _.split(filter, ',') : []

  const getAllTags = (resourcesArray) => {
    const getTags = (resourceObj) => _
      .chain(_.get(resourceObj.resource, resourceObj.edgesString))
      .map((tag) => _.get(tag, `${resourceObj.nodeString}.${resourceObj.tagsString}`))
      .flatten()
      .compact()
      .union()
      .map(tag => ({ name: tag, active: _.indexOf(filters, tag) === -1 }))
      .value()

    const tags = _
      .chain(resourcesArray)
      .map(getTags)
      .flatten()
      .uniqBy('name')
      .value()

    return tags
  }

  const getAllCards = (cardsArray) => {
    const getCardIsActive = (card) => {
      const tags = _.get(card, '_meta.tags')
      const matchingFilters = _.filter(tags, (tag) => _.indexOf(filters, tag) !== -1)
      if (_.size(tags)) {
        return filters.length === 0 || _.size(matchingFilters) < _.size(tags)
      } else {
        return true
      }

    }

    const getCards = (cardsObj) => {
      return (
        _
          .chain(_.get(cardsObj.resource, cardsObj.edgesString))
          .map((card) => _.get(card, cardsObj.nodeString))
          .map(card => ({ [cardsObj.type]: card, active: getCardIsActive(card) }))
          .value()
      )
    }

    const cards = _
      .chain(cardsArray)
      .map(getCards)
      .flatten()
      .sortBy((card) => {
        const { article, whitepaper, quiz } = card || {}
        if (article) { return article.publish_date }
        if (whitepaper) { return whitepaper.publish_date }
        if (quiz) { return quiz.quiz_publish_date }
      })
      .reverse()
      .value()

    return cards
  }

  const handleTagClick = ({ tag, filters }) => {
    if (filters) {
      if (_.indexOf(filters, tag.name) === -1) {
        // add new tag filter
        filters.push(tag.name)
      } else {
        // remove existing tag
        _.remove(filters, (filterTag) => filterTag === tag.name)
      }

      if (filters.length > 0) {
        navigate(`?filter=${filters}`)
      } else {
        navigate(location.pathname)
      }
    } else {
      navigate(`?filter=${tag.name}`)
    }
  }

  return (
    <StaticQuery
      query={`${resourcesQuery}`}
      render={withPreview(({ articles, whitepapers, quiz }) => {
        const resourcesArray = [
          { type: 'article', resource: articles, edgesString: 'allBlog_posts.edges', nodeString: 'node', tagsString: '_meta.tags' },
          { type: 'whitepaper', resource: whitepapers, edgesString: 'allResources.edges.0.node.whitepapers', nodeString: 'whitepaper_page', tagsString: '_meta.tags' },
          { type: 'quiz', resource: quiz, edgesString: 'allResources.edges', nodeString: 'node', tagsString: '_meta.tags' }
        ]
        const tags = getAllTags(resourcesArray)
        const cards = getAllCards(resourcesArray)

        return (
          <Container type={type}>
            {_.size(tags) > 0 && (
              <TagsFilter
                tags={tags}
                handleClick={tag => handleTagClick({ filters, tag })}
              />
            )}
            <Masonry
              breakpointCols={{
                default: 2,
                1200: 2,
                700: 1,
                500: 1,
              }}
              className="blog-masonry-grid"
              columnClassName="blog-masonry-grid_column"
            >
              {_.map(cards, (card, index) => {
                if (_.get(card, 'article')) { return <Card key={index} variant='article' {...card} /> }
                if (_.get(card, 'whitepaper')) { return <Card key={index} variant='whitepaper' {...card} /> }
                if (_.get(card, 'quiz')) { return <Card key={index} variant='quiz' {...card} /> }

              })}
            </Masonry>
          </Container>
        )
      }, resourcesQuery)}
    />
  )
}

export default ResourceListing
